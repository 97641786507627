import { React, useEffect } from 'react'
import { useHistory, Switch, Route } from 'react-router-dom';
import { Text, Link, Center, Box, Stack, Spinner, useToast } from "@chakra-ui/react";
import { useMoralis } from "react-moralis";

import YouwhoLogo from '../../media/logo/YouwhoLogo';
import SignupLoginForm from "../forms/SignupLoginForm";
import PasswordReset from '../forms/PasswordReset';

export default function Home() {

    const { user } = useMoralis();
    const history = useHistory();
    const toast = useToast()

    useEffect(() => {
        if (user) {
            history.push("/main/stake")
        }
        // eslint-disable-next-line
    }, [user])

    return (
        <Center w="100vw" h="100vh">
            <Box w={["100vw", "400px", "400px"]} p={8} boxShadow={["", "md", "md"]} rounded="lg" bg={["", "white", "white"]} >
                <Stack spacing={7} >

                    <Box>
                        <Center>
                            <Link href="https://youwho.io" isExternal>
                                <YouwhoLogo className="youwho-logo" wdth="60px" />
                            </Link>
                        </Center>
                        <Box align="center">
                            <Text className="logo-font" fontWeight="300" fontSize="3xl" >Super<Text as="span" fontWeight="500" >Staker</Text></Text>
                        </Box>
                    </Box>

                    <Stack spacing={3}>
                        {user ?
                            <Center>
                                <Stack align="center" spacing={4}>
                                    <Spinner size="lg" />
                                    <Text fontSize="xl">Logging out...</Text>
                                </Stack>
                            </Center>
                            :
                            <Switch>
                                <Route path="/resetpwd">
                                    <PasswordReset toast={toast} />
                                </Route>
                                <Route path="/">
                                    <SignupLoginForm />
                                </Route>
                            </Switch>
                        }
                    </Stack>

                </Stack>
            </Box>
        </Center>
    )
}
