import { React, useState, useRef, useEffect } from 'react'
import { Text, Button, Box, Avatar, IconButton, Heading, Center, Flex, Divider, Menu, Link, MenuButton, Icon } from "@chakra-ui/react";
import { motion } from "framer-motion"
import { useMoralis } from "react-moralis"

import * as Io5 from 'react-icons/io5'
import * as BsI from 'react-icons/bs'
import * as BiI from 'react-icons/bi'

import YouwhoLogo from '../../media/logo/YouwhoLogo';
import OutsideClick from "./OutsideClick";
import { NavItems, NavLogout } from './NavItems';

const MotionFlex = motion(Flex)
const MotionBox = motion(Box)
const MotionText = motion(Text)

const variants = {
    large: { width: "200px", height: "auto", background: "white", boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.05)" },
    small: { width: "70px", height: "90px", background: "none", boxShadow: "none" },
    flex: { opacity: 1, display: "flex" },
    block: { opacity: 1, display: "block" },
    none: { opacity: 0, display: "none", x: -20 },
}

export default function Sidebar({ username, gravatarUrl, gravatarProfileUrl }) {

    const { Moralis, isAuthenticated, user } = useMoralis();
    const [navSize, setNavSize] = useState("large")
    const [roles, setRoles] = useState([])
    const boxRef = useRef(null);
    const boxOutsideClick = OutsideClick(boxRef);

    useEffect(() => {
        if (boxOutsideClick) setNavSize("small")
    }, [boxOutsideClick])

    useEffect(() => {
        (async () => {
            try {
                if (isAuthenticated) {
                    const query = new Moralis.Query("UserSuperStaker")
                    query.equalTo("user", user)
                    const result = await query.first();
                    if (result?.attributes?.status === "completed") {
                        let roleArr = [];
                        roleArr.push(result?.attributes?.vendor ? "Vendor" : undefined);
                        roleArr.push(result?.attributes?.contributor ? "Contributor" : undefined);
                        setRoles(roleArr);
                    }
                }
            } catch (error) {
                console.error("error getting roles")
            }
        })()
        // eslint-disable-next-line
    }, [isAuthenticated])

    return (
        <MotionFlex
            pos="fixed"
            ml="1vh"
            top="1vh"
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            flexDir="column"
            justify="space-between"
            py={4}
            borderRadius="lg"
            animate={navSize === "large" ? "large" : "small"}
            variants={variants}
            transition={{ duration: 0.5 }}
            zIndex={9999}
            overflow="hidden"
            ref={boxRef}
        >

            < Flex
                flexDir="column"
                alignItems="flex-start"
                as="nav"
                px={2}
            >
                <Flex mr={2} >
                    <Button variant="unstyled" display="inline-flex" onClick={() => {
                        if (navSize === "small")
                            setNavSize("large")
                        else
                            setNavSize("small")
                    }}>
                        <YouwhoLogo wdth="55px" className="youwho-logo" />
                        <MotionBox animate={navSize === "large" ? "block" : "none"}
                            variants={variants}
                            transition={{ duration: 0.7 }}>
                            <Text className="logo-font" fontSize="3xl" _hover={{ fontWeight: '400' }} >
                                ouwho
                            </Text>
                        </MotionBox>
                    </Button>
                </Flex>

                <IconButton
                    w="100%"
                    background="none"
                    variant="underlined"
                    _hover={{ background: 'none' }}
                    color="gray.600"
                    fontSize="20px"
                    icon={<Io5.IoEllipsisHorizontalOutline />}
                    onClick={() => {
                        if (navSize === "small")
                            setNavSize("large")
                        else
                            setNavSize("small")
                    }}
                />
                <Flex flexDir="column" p={4}>
                    <NavItems navSize={navSize} icon={Io5.IoRocketOutline} title="stake" linkTo={"/main/stake"} />
                    <NavItems navSize={navSize} icon={Io5.IoCardOutline} title="buy UHU" linkTo={"/main/buy"} />
                    <NavItems navSize={navSize} icon={Io5.IoSwapHorizontalOutline} title="withdraw" linkTo={"/main/withdraw"} />
                    <NavItems navSize={navSize} icon={BsI.BsQuestionCircle} title="faq" linkTo={"/main/faq"} />

                    <Flex
                        mb="20px"
                        flexDir="column"
                        w="100%"
                        alignItems="flex-start"
                    >
                        <Menu placement="right">
                            <Link _hover={{ textDecoration: 'none', color: "gray.900" }} href={"https://youwho.io"} isExternal color="gray.500">
                                <MenuButton>
                                    <Flex>
                                        <Icon as={BiI.BiHome} fontSize="24px" mr={navSize === "large" ? "4" : "0"} />
                                        <MotionText fontWeight="400" animate={navSize === "large" ? "block" : "none"}
                                            variants={variants}
                                            transition={{ duration: 0.7 }}>homepage</MotionText>
                                    </Flex>
                                </MenuButton>
                            </Link>
                        </Menu>
                    </Flex>

                </Flex>
            </Flex >

            <Flex
                px={4}
                flexDir="column"
                w="100%"
                alignItems="flex-start"
            >
                <Flex flexDir="column" px={2}>
                    <NavItems navSize={navSize} icon={Io5.IoPeopleCircleOutline} title="profile" linkTo={"/main/profile"} />
                    <NavLogout navSize={navSize} icon={Io5.IoLogOutOutline} title="logout" />
                </Flex>
                <Divider display={navSize === "large" ? "flex" : "none"} />
                <Center minH="40px" mt={4}>
                    <Avatar size="sm" src={gravatarProfileUrl ? gravatarUrl : ""} name={username} />
                    <MotionFlex flexDir="column" ml={4} animate={navSize === "large" ? "flex" : "none"}
                        variants={variants}
                        transition={{ duration: 0.7 }}>
                        <Heading as="h3" size="sm">{username}</Heading>
                        <Text color="gray" className="logo-font" fontWeight="300" fontSize="sm">Super<Text as="span" fontWeight="500" >Staker</Text></Text>
                        {roles.map((x) => {
                            return (
                                x ?
                                    <Text key={x} color="gray" className="logo-font" fontWeight="300" fontSize="sm">{x}</Text>
                                    :
                                    <></>
                            )
                        })}
                    </MotionFlex>

                </Center>
            </Flex>
        </MotionFlex >
    )
}
