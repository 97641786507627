import React from 'react'
import { Box, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/react";


export default function AccItem({ accHeading, accContent }) {
    return (
        <AccordionItem>
            <h2>
                <AccordionButton py={4} px={6}>
                    <Box flex="1" textAlign="left">
                        {accHeading}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={[4, 8, 8]} px={[4, 8, 8]}>
                {accContent}
            </AccordionPanel>
        </AccordionItem>
    )
}
