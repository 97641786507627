import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Button, Icon
} from '@chakra-ui/react'


export default function ConfirmModalNoButton({ isOpen, onClose, confirmButtonText, handleConfirm, modalTitle, modalMessage, formikUsername, modalIcon }) {
    return (
        <>
            <Modal isOpen={isOpen} onClose={() => { formikUsername.setSubmitting(false); onClose(); }}>
                <ModalOverlay />
                <ModalContent mt="20vh" mx="2">
                    <ModalHeader fontWeight="400"><Icon as={modalIcon} fontSize="26px" mr="3" mb="2px" />{modalTitle}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>{modalMessage}</ModalBody>

                    <ModalFooter>
                        <Button colorScheme="red" onClick={() => { handleConfirm(true); onClose() }}>{confirmButtonText}</Button>
                        <Button ml={3} onClick={() => { formikUsername.setSubmitting(false); onClose(); }}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
