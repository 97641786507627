import { React } from 'react'
import { NavLink } from 'react-router-dom'
import { Text, Link, Flex, Icon, Menu, MenuButton } from "@chakra-ui/react";
import { motion } from "framer-motion"
import { useMoralis } from "react-moralis";

const MotionText = motion(Text)

const variants = {
    block: { opacity: 1, display: "block" },
    none: { opacity: 0, display: "none", x: -20 },
}

export function NavItems({ navSize, title, icon, linkTo }) {
    return (
        <Flex
            mb="20px"
            flexDir="column"
            w="100%"
            alignItems="flex-start"
        >
            <Menu placement="right">
                <Link as={NavLink} activeStyle={{ color: "#1A202C" }} _hover={{ textDecoration: 'none', color: "gray.900" }} to={linkTo} color="gray.500">
                    <MenuButton>
                        <Flex>
                            <Icon as={icon} fontSize="24px" mr={navSize === "large" ? "4" : "0"} />
                            <MotionText fontWeight="400" animate={navSize === "large" ? "block" : "none"}
                                variants={variants}
                                transition={{ duration: 0.7 }}>{title}</MotionText>
                        </Flex>
                    </MenuButton>
                </Link>
            </Menu>
        </Flex>
    )
}

export function NavLogout({ navSize, title, icon }) {

    const { logout } = useMoralis();

    return (
        <Flex
            mb={2}
            flexDir="column"
            w="100%"
            alignItems="flex-start"
            display={["flex", "none", ""]}
        >
            <Menu placement="right">
                <MenuButton _hover={{ textDecoration: 'none', color: "gray.900" }} onClick={logout} color="gray.500" mb="10px">
                    <Flex>
                        <Icon as={icon} fontSize="24px" mr={navSize === "large" ? "4" : "0"} />
                        <MotionText fontWeight="400" animate={navSize === "large" ? "block" : "none"}
                            variants={variants}
                            transition={{ duration: 0.7 }}>{title}</MotionText>
                    </Flex>
                </MenuButton>
            </Menu>
        </Flex>
    )
}
