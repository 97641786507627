export const Container = {
  // The styles all button have in common
  baseStyle: (props) => ({
    bg: props.colorMode === "light" ? "white" : "gray.900",
  }),
};

export const ModalContent = {
  // The styles all button have in common
  baseStyle: (props) => ({
    bg: props.colorMode === "light" ? "white" : "gray.900",
  }),
};

export const Modal = {
  // The styles all button have in common
  baseStyle: (props) => ({
    bg: props.colorMode === "light" ? "white" : "gray.900",
  }),
};

export const Text = {
  // The styles all button have in common
  baseStyle: (props) => ({
    fontWeight: "300",
  }),
};
