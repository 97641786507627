import { React, useState, useEffect } from 'react'
import { Switch, Route, useHistory } from "react-router-dom";
import { Flex, useToast } from "@chakra-ui/react";
import { useMoralis } from "react-moralis";


import Header from '../header/Header'
import Sidebar from '../sidebar/Sidebar'
import Footer from '../header/Footer'
import Stake from './Stake';
import Buy from './Buy';
import Withdraw from './Withdraw';
import Faq from './Faq';
import Profile from './Profile';



export default function Main() {

    const gravatar = require('gravatar');
    const toast = useToast()
    const { Moralis, user, isAuthenticated } = useMoralis();
    const history = useHistory();
    const [username, setUsername] = useState("Guest")
    const [gravatarUrl, setGravatarUrl] = useState("")
    const [gravatarProfileUrl, setGravatarProfileUrl] = useState("")

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                try {
                    const queryRequest = new Moralis.Query("RequestPurchase");
                    queryRequest.equalTo("user", user);
                    queryRequest.equalTo("status", "pending");
                    const objectRequest = await queryRequest.first();
                    if (objectRequest) history.push("/main/buy");
                } catch (e) {
                    console.error("query request purchase error", e)
                }

                try {
                    const queryOnboarding = new Moralis.Query("OnboardRegister");
                    queryOnboarding.equalTo("user", user);
                    queryOnboarding.equalTo("status", "pending");
                    const objectOnboarding = await queryOnboarding.first();
                    if (objectOnboarding) history.push("/main/profile");
                } catch (e) {
                    console.error("query onboarding error", e)
                }

                if (user?.attributes?.email && !user?.attributes?.emailVerified) {
                    toast({
                        title: "Please verify your email.",
                        description: <>We have sent an email to <b>{user.attributes.email}</b> . Please follow the link to verify your email.</>,
                        status: "warning",
                        duration: 9000,
                        isClosable: true,
                    })
                }
            }
        })()
        // eslint-disable-next-line
    }, [isAuthenticated])

    useEffect(() => {
        let usernameShort = "Guest";
        let gravUrl = "";

        if (isAuthenticated) {
            let getUsername = user.get("username");
            let getEmail = user.get("email");
            let getEthAddress = user.get("ethAddress")
            usernameShort = !getEmail && getEthAddress ? getEthAddress : getUsername ? getUsername : "Guest";
            gravUrl = gravatar.url(user.get("email"), { protocol: 'https', s: '100' });
            fetch(gravatar.profile_url(user.get("email"), { protocol: 'https' }))
                .then(res => res.json())
                .then(result => setGravatarProfileUrl(result.entry[0].profileUrl))
                .catch(error => console.error(error));

            /// setting user superstaker. Will only create a new userSuperStaker the first time user is created
            Moralis.Cloud.run("setUserSuperStaker")
                .catch(error => console.error("set user super staker error in Main", error));
        }

        if (usernameShort.length >= 10) {
            usernameShort = usernameShort.substring(0, 4) + "..." + usernameShort.substring(usernameShort.length - 4)
        }

        setUsername(usernameShort);
        setGravatarUrl(gravUrl)
        // eslint-disable-next-line 
    }, [isAuthenticated])

    return (
        <Flex w="100%" h="100%" minH="100vh" flexDir="column" >

            <Sidebar username={username} gravatarUrl={gravatarUrl} gravatarProfileUrl={gravatarProfileUrl} />

            <Header username={username} />

            <Flex w="100%" maxW="1080px" mx="auto" >

                <Switch>
                    <Route path="/main/stake">
                        <Stake />
                    </Route>
                    <Route path="/main/buy">
                        <Buy />
                    </Route>
                    <Route path="/main/withdraw">
                        <Withdraw />
                    </Route>
                    <Route path="/main/faq">
                        <Faq />
                    </Route>
                    <Route path="/main/profile">
                        <Profile gravatarUrl={gravatarUrl} gravatarProfileUrl={gravatarProfileUrl} />
                    </Route>
                </Switch>

            </Flex>

            <Footer />

        </Flex>
    )

}
