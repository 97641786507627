import { React, useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Text, Spinner, Link, Heading, Center, Flex, Box, Stack, Divider, Icon } from "@chakra-ui/react";
import { useMoralis } from "react-moralis";
import { Duration } from "luxon";
import { motion } from "framer-motion"
import CountUp from 'react-countup';
import { numberWithCommas } from '../../js/utils';

import * as Io5 from 'react-icons/io5'

const MotionBox = motion(Box);

export default function Stake() {

    const { Moralis, isInitialized } = useMoralis();
    const [stakes, setStakes] = useState([]);
    const [hasStake, setHasStake] = useState(false);
    const [totalStaked, setTotalStaked] = useState("");
    const [totalEarned, setTotalEarned] = useState("");
    const [currentApr, setCurrentApr] = useState("");
    const [settingsApr, setSettingsApr] = useState("");
    const [breakdownLoading, setBreakdownLoading] = useState(false);
    const [currentPrice, setCurrentPrice] = useState("");
    const currentDate = new Date();
    const history = useHistory();

    useEffect(() => {
        if (isInitialized) {
            getStakeAmounts()
        }
        // eslint-disable-next-line 
    }, [])

    const getSettings = async () => {
        try {
            let SuperStakerSettings = Moralis.Object.extend("SuperStakerSettings")
            let querySettings = new Moralis.Query(SuperStakerSettings);
            let superStakerSettings = await querySettings.first();
            const { apr, listingPrice } = superStakerSettings.attributes;
            setSettingsApr(apr);
            setCurrentPrice(listingPrice);
        } catch (error) {
            console.error("error getting super staker settings", error)
        }
    }

    const getStakeAmounts = async () => {
        setBreakdownLoading(true)

        let staker;

        try {
            staker = await Moralis.Cloud.run("getStaker")
        }
        catch (e) {
            console.error("getStaker error", e)
            return;
        }

        if (!staker) {
            setBreakdownLoading(false)
            history.push("/main/buy")
            return;
        }
        setHasStake(true);

        const stake = staker.attributes.staked;
        let totalStake = 0;
        let totalStakeApr = 0;
        let totalInterest = 0;

        let currentStakes = stake.map((x, i) => {

            let millis = (currentDate.getTime() - x.stakeDate.getTime());
            let millisInYear = millis / (365 * 24 * 60 * 60 * 1000);
            let interest = x.apr * millisInYear * x.stakeAmount;

            let obj = { start: x.stakeDate, currentDate, millis, amount: x.stakeAmount, apr: x.apr, interest, tx: x.tx };

            totalInterest += obj.interest;
            totalStake += x.stakeAmount;
            totalStakeApr += x.stakeAmount * x.apr;

            return obj;
        })

        setStakes(currentStakes);
        setTotalStaked(totalStake);
        setTotalEarned(totalInterest);
        setCurrentApr(totalStakeApr / totalStake);

        await getSettings();

        setBreakdownLoading(false)
        return;
    }

    if (breakdownLoading) {
        return (
            <Center w="100%" h="50vh" flexDir="column">
                <Spinner size="xl" />
                <br />
                <Text fontSize="xl">Loading ...</Text>
            </Center>
        )
    }

    if (!hasStake) {
        return (
            <Center as={NavLink} to="/main/buy" maxW="600px" p={8} mt="10vh" mx={[4, "auto", "auto"]} flexDir="column" bg={["", "white", ""]} borderRadius="lg" _hover={{ bg: "#f9f9f9" }} boxShadow={["", "md", ""]} border={["", "1px", ""]} borderColor={["", "gray.100", "gray.100"]}>
                <Icon as={Io5.IoHandLeftOutline} fontSize="60px" color="primary.500" /><br />
                <Text fontSize="xl" align="center"><Text as="span" fontSize="3xl" fontWeight="500">Current Staking APR : <Text as="span" fontWeight="700">{settingsApr * 100}%</Text></Text>
                    <br />
                    You are currently not staking any <Text as="span" fontWeight="500">UHU</Text> tokens.<br />Click here to buy and automagically <Text as="span" className="logo-font" fontWeight="300" >Super<Text as="span" fontWeight="500" >Stake</Text></Text> tokens.</Text>
            </Center>
        )
    }

    return (
        <Flex minH="50vh" w="100%" p={[0, 2, 2]} alignItems="flex-start" flexWrap="wrap">
            <Flex
                minH="400px"
                bg="white"
                boxShadow={[0, "md", "md"]}
                flexDir="column"
                pb={["70px", "30px", ""]}
                m={[0, 2, 2]}
                borderRadius={[0, "lg", "lg"]}
                alignItems="center"
                flexGrow="1"
                w={["100%", "auto", "auto"]}
                minW={["", "450px", ""]}
                border="1px" borderColor={["gray.100", "gray.100", "gray.100"]}
            >
                <Box
                    w="100%"
                    px={6}
                    pt="24px"
                    pb={5}
                    display="inline-flex"
                    alignItems="center"
                    borderTopRadius={[0, "lg", "lg"]}
                >
                    <Icon as={Io5.IoRocketOutline} fontSize={["3xl", "4xl", "4xl"]} mr={4} color="primary.500" /><Text fontSize={["2xl", "2xl", "2xl"]} fontWeight="400">Staking Summary</Text>
                </Box>
                <Center h="100%" flexDir="column" w="100%">
                    <MotionBox bg="#f9f9f9" w="100%"
                        animate={{ y: [-20, 0], opacity: [0, 1] }}
                        transition={{ delay: 0.2, duration: 1.5, ease: "easeOut" }}>
                        <Divider />
                        <br />
                        <Heading as="h3" align="center" size="2xl" ><CountUp start={(totalStaked + totalEarned) * 2 / 3 / 10 ** 18} end={(totalStaked + totalEarned) / 10 ** 18} separator="," easingFn={[0.33, 1, 0.68, 1]} decimals={0} duration={1} /></Heading><Text fontSize="lg" align="center">UHU tokens total</Text><Text fontSize="lg" align="center"><Text as="span" fontWeight="500">${(currentPrice * (totalStaked + totalEarned) / 10 ** 18).toFixed(2)}</Text> listing valuation</Text>
                        <br />
                        <Divider />
                    </MotionBox>
                    <br />
                    <MotionBox
                        animate={{ y: [-20, 0], opacity: [0, 1] }}
                        transition={{ delay: 0.4, duration: 1.5, ease: "easeOut" }}>
                        <Heading as="h3" align="center" size="xl" fontWeight="500"><CountUp start={totalStaked * 2 / 3 / 10 ** 18} end={totalStaked / 10 ** 18} separator="," easingFn={[0.33, 1, 0.68, 1]} decimals={0} duration={1} /></Heading><Text align="center">UHU tokens staked</Text>
                    </MotionBox>
                    <br />
                    <MotionBox
                        animate={{ y: [-20, 0], opacity: [0, 1] }}
                        transition={{ delay: 0.6, duration: 1.5, ease: "easeOut" }}>
                        <Heading as="h3" align="center" size="xl" fontWeight="500"><CountUp start={currentApr * 100 * 2 / 3} end={currentApr * 100} separator="," suffix="%" easingFn={[0.33, 1, 0.68, 1]} decimals={1} duration={1} /></Heading><Text align="center">Annual Percentage Rate</Text>
                    </MotionBox>
                    <br />
                    <MotionBox
                        animate={{ y: [-20, 0], opacity: [0, 1] }}
                        transition={{ delay: 0.8, duration: 1.5, ease: "easeOut" }}>
                        <Heading as="h1" align="center" size="xl" fontWeight="500"><CountUp start={totalEarned * 2 / 3 / 10 ** 18} end={totalEarned / 10 ** 18} separator="," easingFn={[0.33, 1, 0.68, 1]} decimals={0} duration={1} /></Heading><Text align="center">UHU tokens accrued</Text>
                    </MotionBox>
                </Center>
            </Flex>

            <Flex
                minH="300px"
                bg="white"
                boxShadow={[0, "md", "md"]}
                flexDir="column"
                borderRadius={[0, "lg", "lg"]}
                flexGrow="2"
                m={[0, 2, 2]}
                w={["100%", "50%", "50%"]}
                border={["0", "1px", ""]}
                borderColor={["gray.100", "gray.100", "gray.100"]}
            >
                <Box
                    pt="24px"
                    px={6}
                    pb={5}
                    display="inline-flex"
                    alignItems="center"
                    // bg="#f5f5f5"
                    borderTopRadius={[0, "lg", "lg"]}
                >
                    <Icon as={Io5.IoReceiptOutline} fontSize={["3xl", "4xl", "4xl"]} mr={4} color="primary.500" /><Text fontSize={["2xl", "2xl", "2xl"]} fontWeight="400">Staking Breakdown</Text>
                </Box>

                <Stack spacing={0}>
                    {stakes && stakes.slice(0).reverse().map((x, i) =>

                        <MotionBox
                            pb={4}
                            px={6}
                            as={Link}
                            href={x.tx ? `https://etherscan.io/tx/${x.tx}` : "#rewarded"}
                            isExternal={x.tx ? true : false}
                            _hover={{ textDecoration: 'none', bg: "#f9f9f9" }}
                            animate={{ y: [-20, 0], opacity: [0, 1] }}
                            transition={{ delay: 0.4 * i, duration: 1.5, ease: "easeOut" }}
                            key={`MotionBox ${i}`}
                        >
                            <Divider key={`divider ${i}`} mb={4} />
                            <Box key={`box ${i}`} px={[2, 8, 8]} fontSize="lg">
                                <Text key={`lot ${i}`} fontWeight="500" fontSize="xl">Lot #{stakes.length - i} {x.tx ? <Icon as={Io5.IoOpenOutline} color="gray" ml={2} mb="5px" /> : <Text as="span" color="gray" fontSize="16px">(rewarded)</Text>}</Text>
                                <Text key={`amount ${i}`}>Staked <Text as="span" fontWeight="500">{numberWithCommas((x.amount / (10 ** 18)).toFixed(0))} UHU</Text></Text>
                                <Text key={`start ${i}`}>Started staking on <Text as="span" fontWeight="500">{x.start.toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Text></Text>
                                <Text key={`duration ${i}`}>Staked for <Text as="span" fontWeight="500">{Duration.fromMillis(x.millis).toFormat("d' days' h' hours and ' m' minutes'")}</Text></Text>
                                <Text key={`earning ${i}`}>Earning at <Text as="span" fontWeight="500">{x.apr * 100}% APR</Text></Text>
                                <Text key={`earned ${i}`}>Tokens accrued to date <Text as="span" fontWeight="500">{numberWithCommas((Math.round(x.interest / (10 ** 16)) / 100).toFixed(0))} UHU</Text></Text>
                            </Box>
                        </MotionBox>

                    )}

                    <MotionBox
                        pb={4}
                        px={6}
                        as={NavLink}
                        to={"/main/buy"}
                        _hover={{ textDecoration: 'none', bg: "#f5f5f5" }}
                        animate={{ y: [-20, 0], opacity: [0, 1] }}
                        transition={{ delay: 0.4 * (stakes.length), duration: 1.5, ease: "easeOut" }}
                        bg="#f9f9f9"
                        borderBottomRadius={[0, "lg", "lg"]}
                    >
                        <Divider mb={4} />
                        <Box px={[2, 8, 8]} fontSize="lg">
                            <Text fontWeight="500" fontSize="xl" mb="10px">Stake more <Icon as={Io5.IoLogoStackoverflow} mb="5px" fontSize="24px" /></Text>
                            <Text >Click here to buy and automagically <Text as="span" className="logo-font" fontWeight="300" >Super<Text as="span" fontWeight="500" >Stake</Text></Text> more <Text as="span" fontWeight="500">UHU</Text> tokens</Text>
                        </Box>
                    </MotionBox>

                </Stack>
            </Flex>


        </Flex >
    )
}
