import { React, useState, useEffect } from 'react'
import { useMoralis } from "react-moralis";
import { useHistory } from 'react-router-dom';
import { Stack, Input, Spacer, InputGroup, Icon, IconButton, Link, InputLeftElement, FormControl, Button, FormErrorMessage, FormHelperText, FormLabel, Center, Text, Flex, Radio, RadioGroup, HStack, PinInput, PinInputField, Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { numberWithCommas } from '../../js/utils';

import YouwhoLogo from '../../media/logo/YouwhoLogo';
import * as BiI from 'react-icons/bi'
import * as MdI from 'react-icons/md'
import * as Io5 from 'react-icons/io5'

export default function Request() {

    const { user, signup, authError, Moralis, isInitialized, web3 } = useMoralis();
    const history = useHistory();
    const [received, setReceived] = useState(false);
    const [blockchain, setBlockchain] = useState("ethereum");
    const [usdTierArray, setUsdTierArray] = useState(null);
    const [salePrice, setSalePrice] = useState(null);
    const [ethAddressError, setEthAddressError] = useState("");

    const validationSchema = yup.object().shape({
        username: yup.string().min(3, "Username must be atleast 3 characters long.").max(30, "Username must be 30 characters or less.").required("Username is required."),
        email: yup.string().email("Please enter a valid email.").required("Email is required."),
        password: yup.string().required("Password is required.").matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character."
        ),
        passwordConfirm: yup.string().required("Password confirmation is required.").oneOf([yup.ref('password'), null], 'Passwords must match.'),
        amount: yup.string().required('USD Amount is required.'),
        other: yup.number().when("amount", {
            is: "other",//just an e.g. you can return a function
            then: yup.number().positive().min(40001, "USD Amount must be greater than $40,000 USD").required('USD Amount is required.'),
        }),
        pin1: yup.number().moreThan(-1).lessThan(10).integer().required("Four digit pin required."),
        pin2: yup.number().moreThan(-1).lessThan(10).integer().required("Four digit pin required."),
        pin3: yup.number().moreThan(-1).lessThan(10).integer().required("Four digit pin required."),
        pin4: yup.number().moreThan(-1).lessThan(10).integer().required("Four digit pin required."),
        checkboxes: yup.array().min(3, "You must tick all checkboxes."),
        address: yup.string().length(42, "Please enter a valid Ethereum Address").required("Ethereum Address is required."),
    })

    const formik = useFormik({
        initialValues: {
            username: "",
            email: "",
            password: "",
            passwordConfirm: "",
            pin1: "",
            pin2: "",
            pin3: "",
            pin4: "",
            amount: "100",
            other: "",
            blockchain: "ethereum",
            stablecoin: "usdt",
            checkboxes: [],
            address: "",
        },
        onSubmit: (values) => {
            handleRequest(values);

            // formik.setSubmitting(false);
        },
        validationSchema
    })

    useEffect(() => {
        if (isInitialized) {
            (async () => {
                try {
                    let SuperStakerSettings = Moralis.Object.extend("SuperStakerSettings")
                    let querySettings = new Moralis.Query(SuperStakerSettings);
                    let superStakerSettings = await querySettings.first();
                    let jsonSuperStakerSettings = JSON.stringify(superStakerSettings.attributes)
                    console.log("superstakersettings", jsonSuperStakerSettings)
                    const { presalePrice, usdTier } =
                        superStakerSettings.attributes;
                    setSalePrice(presalePrice)
                    setUsdTierArray(usdTier)
                } catch (e) {
                    console.error("error getting super staker settings", e)
                }
            })();
        }
        // eslint-disable-next-line
    }, [isInitialized])

    useEffect(() => {
        if (user) {
            if (received) {
                setTimeout(() => history.push("/main/buy"), 5000)
            } else {
                history.push("/main/buy")
            }
        }
        // eslint-disable-next-line
    }, [user])

    const handleRequest = async (values) => {

        let customValidationOk = (values) => {
            let checkAddress = web3.utils.isAddress(values.address)
            if (!checkAddress) setEthAddressError("Please enter a valid Ethereum Address");
            let pass = checkAddress ? true : false;
            return pass;
        }

        if (customValidationOk(values)) {

            try {
                const { username, password, email, pin1, pin2, pin3, pin4, amount, other, stablecoin, checkboxes, address } = values;
                await signup(username, password, email)
                await Moralis.Cloud.run("setRequestUhu", { pin1, pin2, pin3, pin4, amount, other: String(other), stablecoin, checkboxes, address })
                setReceived(true);
            } catch (e) {
                console.error("error from signup", e);
            }
        }
        formik.setSubmitting(false);
    }

    if (received) {
        return (
            <Center h="100vh" w="100vw">
                <Center maxW="600px" p={8} mb="30vh" mx={[4, "auto", "auto"]} flexDir="column" bg={["", "white", ""]} borderRadius="lg" _hover={{ bg: "#f5f5f5" }} boxShadow={["", "md", ""]} border={["", "1px", ""]} borderColor="gray.100" >
                    <Icon as={MdI.MdOutlineMarkEmailRead} fontSize="60px" color="primary.500" /><br />
                    <Text fontSize="xl" align="center"><Text as="span" fontSize="3xl" fontWeight="500"><Text as="span" fontWeight="700">UHU</Text> Request Success</Text><br />Thank you for your purchase request.<br />Please allow up to 24hrs for your request to be approved for payment.<br />You will be redirected to your dashboard in 5 seconds.</Text>
                </Center>
            </Center >
        )
    }

    return (
        <Center w="100vw">
            <Flex
                minH="400px"
                maxW="600px"
                bg="white"
                boxShadow={[0, "md", "md"]}
                flexDir="column"
                borderRadius={[0, "lg", "lg"]}
                m={[0, 4, 4]}
                flexGrow="1"
            >
                <Flex
                    pt="24px"
                    px={6}
                    pb={5}
                    justifyContent={["center", "center", "flex-start"]}
                    borderTopRadius={[0, "lg", "lg"]}
                    borderBottom="1px"
                    borderColor="gray.100"
                    bg='#f5f5f5'
                    flexDir="column"
                >
                    <Flex alignItems="center">
                        <Flex mr={2}>
                            <YouwhoLogo wdth="50px" />
                        </Flex>
                        <Text color="gray" fontSize={["2xl", "2xl", "2xl"]} fontWeight="400"><Text as="span" fontWeight="500">UHU</Text> Purchase Request</Text>
                        <Spacer />
                        <IconButton icon={<BiI.BiLeftArrowCircle />} variant="unstyled" fontSize={["3xl", "4xl", "4xl"]} color="gray" _hover={{ color: "primary.500" }} onClick={() => history.push("/")} />
                    </Flex>
                    <Flex px={2} pt={2}>
                        <Text color="gray" fontSize="sm">Request purchase of <Text as="span" fontWeight="500">UHU</Text> tokens, the native token for the <Text as="span" className="logo-font">youwho</Text> ecosystem. Once your request is submitted it will be reviewed by our admins.</Text>
                    </Flex>
                </Flex>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={8} p={8} >

                        <FormControl isInvalid={formik.touched.username && formik.errors.username}>

                            <FormLabel m={0}>1. Username *</FormLabel>
                            <FormHelperText m={0}>Used to uniquely identify you in the <Text as="span" className="logo-font">youwho</Text> ecosystem.</FormHelperText>
                            <InputGroup mt={1}>
                                <InputLeftElement children={<Icon as={BiI.BiUser} mt="8px" w="20px" h="20px" />} />
                                <Input
                                    focusBorderColor="primary.500"
                                    variant="flushed"
                                    size="lg"
                                    id="username"
                                    type="text"
                                    placeholder="Username"
                                    aria-label="Username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    fontWeight="300"
                                />
                            </InputGroup>
                            <FormErrorMessage>{formik.touched.username && formik.errors.username}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.email && formik.errors.email}>
                            <FormLabel m={0}>2. Email *</FormLabel>
                            <FormHelperText m={0}>Used to login to the <Text as="span" className="logo-font">youwho</Text> ecosystem. We will never share your email address.</FormHelperText>
                            <InputGroup mt={1}>
                                <InputLeftElement children={<Icon as={BiI.BiEnvelope} mt="8px" w="20px" h="20px" />} />
                                <Input
                                    focusBorderColor="primary.500"
                                    variant="flushed"
                                    size="lg"
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    fontWeight="300"
                                />
                            </InputGroup>
                            <FormErrorMessage>{formik.touched.email && formik.errors.email}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.password && formik.errors.password}>
                            <FormLabel m={0}>3. Password *</FormLabel>
                            <FormHelperText m={0}>Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character.</FormHelperText>
                            <InputGroup mt={1}>
                                <InputLeftElement children={<Icon as={BiI.BiKey} mt="8px" w="20px" h="20px" />} />
                                <Input
                                    focusBorderColor="primary.500"
                                    variant="flushed"
                                    size="lg"
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    aria-label="Password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    fontWeight="300"
                                />
                            </InputGroup>
                            <FormErrorMessage>{formik.touched.password && formik.errors.password}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.passwordConfirm && formik.errors.passwordConfirm}>
                            <FormLabel m={0}>4. Confirm Password *</FormLabel>
                            <InputGroup mt={1}>
                                <InputLeftElement children={<Icon as={BiI.BiKey} mt="8px" w="20px" h="20px" />} />
                                <Input
                                    focusBorderColor="primary.500"
                                    variant="flushed"
                                    size="lg"
                                    id="passwordConfirm"
                                    type="password"
                                    placeholder="Confirm password"
                                    aria-label="Confirm password"
                                    value={formik.values.passwordConfirm}
                                    onChange={formik.handleChange}
                                    fontWeight="300"
                                />
                            </InputGroup>
                            <FormErrorMessage>{formik.touched.passwordConfirm && formik.errors.passwordConfirm}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.pin1 || formik.errors.pin1 || formik.touched.pin2 || formik.errors.pin2 || formik.touched.pin3 || formik.errors.pin3 || formik.touched.pin4 || formik.errors.pin4} >
                            <FormLabel m={0}>5. Four Digit Email Verification Pin *</FormLabel>
                            <FormHelperText m={0}>We will include this 4 digit pin in our email correspondence sent to you regarding the UHU token purchase request to verify that the email originated from us.<br />DO NOT take action on any emails that may seem to be from us but do not contain this pin.</FormHelperText>
                            <HStack mt={4} ml={2}>
                                <Icon as={BiI.BiLock} w="20px" h="20px" />
                                <PinInput
                                    id="pin"
                                    focusBorderColor="primary.500"
                                >
                                    <PinInputField id="pin1" name="pin1" onChange={formik.handleChange} />
                                    <PinInputField id="pin2" name="pin2" onChange={formik.handleChange} />
                                    <PinInputField id="pin3" name="pin3" onChange={formik.handleChange} />
                                    <PinInputField id="pin4" name="pin4" onChange={formik.handleChange} />
                                </PinInput>
                            </HStack>
                            <FormErrorMessage>{formik.touched.pin1 || formik.errors.pin1 || formik.touched.pin2 || formik.errors.pin2 || formik.touched.pin3 || formik.errors.pin3 || formik.touched.pin4 || formik.errors.pin4}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={(formik.touched.amount && formik.errors.amount) || (formik.touched.other && formik.errors.other)}>
                            <FormLabel m={0}>6. Purchase Request Tier *</FormLabel>
                            <FormHelperText m={0}>Base token price is ${salePrice} USD per token. Bonus token % increases with every Tier. For purchases greater than $40,000 USD tick the 'Other' option</FormHelperText>
                            <RadioGroup
                                id="amount"
                                value={formik.values.amount}
                                aria-label="Amount"
                            >
                                <Stack px={4} mt={4} spacing="3" color="gray.600">
                                    {usdTierArray && usdTierArray.map((x, i) =>
                                        <Radio value={`${x.usd}`} key={x.usd} colorScheme="red" name="amount" onChange={formik.handleChange}>
                                            <Text as="span" fontWeight="500">${x.usd} USD</Text>
                                            <br />
                                            <Text as="span" color="gray">{numberWithCommas(Math.ceil(x.usd / salePrice))} {x.bonus > 0 ? `+ ${numberWithCommas(Math.ceil((x.usd / salePrice) * x.bonus))} ` : ""}<Text as="span" fontWeight="500">UHU</Text> tokens ({x.bonus * 100}% bonus tokens)</Text>
                                            {x.nft > 0 &&
                                                <>
                                                    <br />
                                                    <Text as="span" color="gray">+ {x.nft} Special Bonus Unique <Text as="span" className="logo-font">youwho</Text> NFT{x.nft > 1 ? "s" : ""}</Text>
                                                </>
                                            }
                                        </Radio>
                                    )}

                                    <Radio value="other" colorScheme="red" name="amount" onChange={formik.handleChange}>
                                        <Text as="span" fontWeight="500">Other</Text>
                                        <br />
                                        <Text as="span" color="gray" display={formik.values.amount === "other" ? "inline" : "none"}>Enter amount greater than $40,000 USD</Text>
                                    </Radio>
                                    <Flex display={formik.values.amount === "other" ? "flex" : "none"}>
                                        <InputGroup mt={1} ml="40px" w="50%">
                                            <InputLeftElement children={<Icon as={BiI.BiDollar} w="20px" h="20px" />} />
                                            <Input
                                                focusBorderColor="primary.500"
                                                variant="flushed"
                                                id="other"
                                                type="number"
                                                placeholder="Enter amount in USD"
                                                aria-label="Other"
                                                value={formik.values.other}
                                                onChange={formik.handleChange}
                                                fontWeight="300"
                                            />
                                        </InputGroup>
                                    </Flex>
                                </Stack>
                            </RadioGroup>
                            <FormErrorMessage>{(formik.touched.amount && formik.errors.amount) || (formik.touched.other && formik.errors.other)}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.blockchain && formik.errors.blockchain}>
                            <FormLabel m={0}>7a. Select Blockchain For Payment. *</FormLabel>
                            <FormHelperText m={0}>When you send payment you must use the Blockchain selected.</FormHelperText>
                            <RadioGroup
                                id="blockchain"
                                value={formik.values.blockchain}
                                aria-label="Blockchain"
                            >
                                <Stack px={4} mt={4} spacing="3" color="gray.600">
                                    <Radio value="ethereum" colorScheme="red" name="blockchain" onChange={e => { formik.handleChange(e); setBlockchain(e.target.value) }}>
                                        <Text as="span" fontWeight="500">Ethereum Main Chain</Text>
                                    </Radio>
                                    <Radio value="polygon" colorScheme="red" name="blockchain" onChange={e => { formik.handleChange(e); setBlockchain(e.target.value) }}>
                                        <Text as="span" fontWeight="500">Polygon Chain (Ethereum Layer 2)</Text>
                                    </Radio>
                                    <Radio value="bsc" colorScheme="red" name="blockchain" onChange={e => { formik.handleChange(e); setBlockchain(e.target.value) }}>
                                        <Text as="span" fontWeight="500">Binance Smart Chain</Text>
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                            <FormErrorMessage>{formik.touched.blockchain && formik.errors.blockchain}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.stablecoin && formik.errors.stablecoin}>
                            <FormLabel m={0}>7b. Select the ERC20 USD Stablecoin you will pay with *</FormLabel>
                            <FormHelperText m={0}>An email will be sent to you requesting the purchase amount be sent using the selected stablecoin.</FormHelperText>
                            <RadioGroup
                                id="stablecoin"
                                value={formik.values.stablecoin}
                                aria-label="Stablecoin"
                            >
                                <Stack px={4} mt={4} spacing="3" color="gray.600">
                                    <Radio value="usdt" colorScheme="red" name="stablecoin" onChange={formik.handleChange}>
                                        <Text as="span" fontWeight="500">USDT</Text>
                                        <br />
                                        <Text as="span" color="gray">Tether USD</Text>
                                        <br />
                                        <Text as="span" color="gray"><Link className="no-text-decor" href={blockchain === "ethereum" ? "https://etherscan.io/" : blockchain === "polygon" ? "https://polygonscan.com/" : "https://bscscan.com/"} isExternal>( contract # {blockchain === "ethereum" ? "0x" : blockchain === "polygon" ? "poly" : "bsc"} <Icon as={Io5.IoOpenOutline} ml={2} mb="5px" /> )</Link></Text>
                                    </Radio>
                                    <Radio value="usdc" colorScheme="red" name="stablecoin" onChange={formik.handleChange}>
                                        <Text as="span" fontWeight="500">USDC</Text>
                                        <br />
                                        <Text as="span" color="gray">USD Coin</Text>
                                        <br />
                                        <Text as="span" color="gray"><Link className="no-text-decor" href={blockchain === "ethereum" ? "https://etherscan.io/" : blockchain === "polygon" ? "https://polygonscan.com/" : "https://bscscan.com/"} isExternal>( contract # {blockchain === "ethereum" ? "0x" : blockchain === "polygon" ? "poly" : "bsc"} <Icon as={Io5.IoOpenOutline} ml={2} mb="5px" /> )</Link></Text>
                                    </Radio>
                                    <Radio value="dai" colorScheme="red" name="stablecoin" onChange={formik.handleChange}>
                                        <Text as="span" fontWeight="500">DAI</Text>
                                        <br />
                                        <Text as="span" color="gray">MakerDAO DAI</Text>
                                        <br />
                                        <Text as="span" color="gray"><Link className="no-text-decor" href={blockchain === "ethereum" ? "https://etherscan.io/" : blockchain === "polygon" ? "https://polygonscan.com/" : "https://bscscan.com/"} isExternal>( contract # {blockchain === "ethereum" ? "0x" : blockchain === "polygon" ? "poly" : "bsc"} <Icon as={Io5.IoOpenOutline} ml={2} mb="5px" /> )</Link></Text>
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                            <FormErrorMessage>{formik.touched.stablecoin && formik.errors.stablecoin}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={(formik.touched.address && formik.errors.address) || ethAddressError}>

                            <FormLabel m={0}>8. Ethereum address you will pay from *</FormLabel>
                            <FormHelperText m={0}>This will be used to verify the stablecoins came from you.</FormHelperText>
                            <InputGroup mt={1}>
                                <InputLeftElement children={<Icon as={BiI.BiWallet} mt="8px" w="20px" h="20px" />} />
                                <Input
                                    focusBorderColor="primary.500"
                                    variant="flushed"
                                    size="lg"
                                    id="address"
                                    type="text"
                                    placeholder="Eth Address"
                                    aria-label="Eth Address"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    fontWeight="300"
                                />
                            </InputGroup>
                            <FormErrorMessage>{(formik.touched.address && formik.errors.address) || ethAddressError}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.checkboxes && formik.errors.checkboxes}>
                            <FormLabel m={0}>9. Read and tick all of the boxes below to proceed with request *</FormLabel>

                            <CheckboxGroup
                                colorScheme="red"
                                id="checkboxes"
                            >
                                <Stack px={4} mt={4} spacing="3">
                                    <Checkbox name="checkboxes" onChange={formik.handleChange} value="1"><Text fontSize="sm">I have read the 'youwho' lightpaper and understand the direction/timeline of the project. (lightpaper can be viewed at: <Link isExternal href="https://bit.ly/UHULPUX" color="primary.500">https://bit.ly/UHULPUX</Link>)</Text></Checkbox>
                                    <Checkbox name="checkboxes" onChange={formik.handleChange} value="2"><Text fontSize="sm">I understand and accept that my UHU tokens will be locked in <Text as="span" className="logo-font" fontWeight="300" >Super<Text as="span" fontWeight="500" >Staker</Text></Text> until the token officially launches on the Ethereum blockchain.</Text></Checkbox>
                                    <Checkbox name="checkboxes" onChange={formik.handleChange} value="3"><Text fontSize="sm">I understand the local laws and regulations related to token sales in my jurisdiction and agree to proceed with request.</Text></Checkbox>
                                </Stack>
                            </CheckboxGroup>

                            <FormErrorMessage>{formik.touched.checkboxes && formik.errors.checkboxes}</FormErrorMessage>
                        </FormControl>

                        <Button type="submit" isLoading={formik.isSubmitting} variant="outline" fontWeight="500" size="lg" fontSize="xl">Submit Request</Button>
                        {authError && <Flex><Text color="primary.500" fontWeight="500" align="center">{authError.message}</Text></Flex>}
                    </Stack>
                </form>
            </Flex>

        </Center >
    )
}
