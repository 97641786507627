import { React, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Text, Link, ListItem, ListIcon, List, Flex, Box, Icon, Accordion } from "@chakra-ui/react";

import * as CgI from 'react-icons/cg'
import * as RiI from 'react-icons/ri'
import * as BsI from 'react-icons/bs'
import * as BiI from 'react-icons/bi'
import * as AiI from 'react-icons/ai'
import * as MdI from 'react-icons/md'

import AccItem from '../accordian/AccItem'

export default function Faq() {

    const { hash } = useLocation();

    useEffect(() => {
        if (hash !== '') {
            // Push onto callback queue so it runs after the DOM is updated,
            // this is required when navigating from a different page so that
            // the element is rendered on the page before trying to getElementById.

            setTimeout(() => {
                const di = (hash.replace('#', ''));
                const element = document.getElementById(di);

                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 1000);

        }
        // eslint-disable-next-line
    }, [])


    return (
        <Flex minH="50vh" w="100%" p={[0, 2, 2]} alignItems="flex-start" flexWrap="wrap">

            <Flex
                bg="white"
                boxShadow={[0, "md", "md"]}
                flexDir="column"
                borderRadius={[0, "lg", "lg"]}
                m={[0, 2, 2]}
                flexGrow="1"
                overflow="hidden"
                border="1px" borderColor="gray.100"
            >
                <Box
                    pt="24px"
                    px={6}
                    pb={5}
                    display="inline-flex"
                    alignItems="center"
                    bg={["#f9f9f9", "#f5f5f5", ""]}
                    borderTopRadius={[0, "lg", "lg"]}

                >
                    <Icon as={BsI.BsQuestionCircle} fontSize={["3xl", "4xl", "4xl"]} mr={4} color="primary.500" /><Text color="gray" fontSize={["2xl", "2xl", "2xl"]} fontWeight="400">Frequently Asked Questions</Text>
                </Box>

                <Accordion defaultIndex={hash === "#users" ? [5] : [0, 5]} allowMultiple>

                    <AccItem
                        accHeading={
                            <>
                                <Text fontWeight="500" fontSize="xl">What is <Text as="span" className="logo-font">youwho</Text> ?</Text>
                            </>
                        }

                        accContent={
                            <>
                                <Text as="span" className="logo-font" fontSize="lg">youwho</Text> is the #1 Crypto Based On-Demand Services Platform. We aim to empower anyone to be their own Boss by combining the world's leading marketplaces, social networks, and banking platforms such as Airbnb, Uber, Grab, Ebay, Amazon, Fiverr, Facebook, and Paypal under one ecosystem, while utilizing cryptocurrencies to settle payments worldwide.
                            </>
                        }
                    />

                    <AccItem
                        accHeading={
                            <>
                                <Text fontWeight="500" fontSize="xl">Why was <Text as="span" className="logo-font">youwho</Text> created?</Text>
                            </>
                        }

                        accContent={
                            <>
                                At the time of the inception of <Text as="span" className="logo-font" fontSize="lg">youwho</Text>, the world is facing an unprecedented pandemic (COVID-19) with millions of people losing their jobs worldwide. Many of these people may never get the chance to return to their previous jobs, whether it be due to the fact that the company they were working for has closed permanently, or the company is downsizing, or a myriad of other reasons.
                                <br /><br />
                                There may also be other people who have been furloughed or had their working hours reduced as a compensatory measure taken by companies to survive the economic impact of the pandemic. As a result there will be a substantial number of skilled people that are unemployed or have reduced incomes.
                                <br /><br />
                                We thought about how useful it would be if there was some way that these skilled people could offer their products and services to anyone anywhere in the world, effectively becoming self employed.
                                <br /><br />
                                This is why <Text as="span" className="logo-font" fontSize="lg">youwho</Text> was created.
                            </>
                        }
                    />

                    <AccItem
                        accHeading={
                            <>
                                <Text fontWeight="500" fontSize="xl">What are <Text as="span" fontWeight="600">YOU</Text> tokens used for?</Text>
                            </>
                        }

                        accContent={
                            <>
                                <Text as="span" fontWeight="500">YOU</Text> tokens will be the native token for the <Text as="span" className="logo-font" fontSize="lg">youwho</Text> ecosystem and will initially be created on the Binance Smart Chain as BEP20 tokens. <Text as="span" fontWeight="500">YOU</Text> tokens will then be bridged over to the Ethereum + Polygon L2 Chain following the successful token launch on BSC.
                                <br /><br />
                                <Text as="span" fontWeight="500">YOU</Text> tokens will be used for <Text as="span" fontWeight="500">Governance</Text>, <Text as="span" fontWeight="500">Arbitration</Text>, and as <Text as="span" fontWeight="500">Reward</Text> incentives throughout the <Text as="span" className="logo-font" fontSize="lg">youwho</Text> Ecosystem.
                                <br /><br />
                                <List spacing={3} px={4}>
                                    <ListItem>
                                        <ListIcon as={RiI.RiGovernmentFill} color="primary.500" fontSize="20px" />
                                        <Text as="span" fontWeight="500">Governance</Text> - Decisions related to steering the development team, deciding on ecosystem fees and rewards, and any community related decisions will be partly controlled by <Text as="span" fontWeight="500">YOU</Text> token stakers, and a DAO acting on their behalf.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={RiI.RiScalesFill} color="primary.500" fontSize="22px" />
                                        <Text as="span" fontWeight="500">Arbitration</Text> - Relates to resolving disputes amongst users. Token holders will be able to take part in arbitration and earn <Text as="span" fontWeight="500">YOU</Text> token rewards for their effort.
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon as={RiI.RiAwardFill} color="primary.500" fontSize="22px" />
                                        <Text as="span" fontWeight="500">Rewards</Text> - As evidenced by popular marketplace websites such as Amazon.com or Ebay.com, most users do not review the products they purchase, simply due to the fact they do not feel the need to do so. As <Text as="span" className="logo-font" fontSize="lg">youwho</Text> is a tokenized ecosystem which requires community input to be self regulating, the higher the number of reviewers for both the customers and providers/merchants, the more safe and trustworthy the ecosystem will be.
                                        <br />
                                        We feel that the best way to ensure both all users review each other is to incentivize the rating process. This can be achieved by rewarding both sides with <Text as="span" fontWeight="500">YOU</Text> tokens as part of the rating process after every order.
                                    </ListItem>
                                </List>
                            </>
                        }
                    />

                    <AccItem
                        accHeading={
                            <>
                                <Text fontWeight="500" fontSize="xl">Where can I buy <Text as="span" fontWeight="600">YOU</Text> tokens?</Text>
                            </>
                        }

                        accContent={
                            <>
                                You can request to purchase <Text as="span" fontWeight="500">YOU</Text> tokens at the following link: <Link href="/request" isExternal color="primary.500">ss.youwho.io/request</Link>.
                            </>
                        }
                    />

                    <AccItem
                        accHeading={
                            <>
                                <Text fontWeight="500" fontSize="xl">When can I withdraw my <Text as="span" fontWeight="600">YOU</Text> tokens?</Text>
                            </>
                        }

                        accContent={
                            <>
                                <Text as="span" fontWeight="500">YOU</Text> tokens will be locked in <Text as="span" className="logo-font" fontWeight="300" >Super<Text as="span" fontWeight="500" >Staker</Text></Text> which is our accelerated staking app for early investors and participants earning a very high yield, as our way of saying thank you and giving back to our community.
                                <br /><br />
                                Once the project is nearing early beta access for its first platform which at the moment is slated to be the <Text className="alt-font" fontWeight="500" as="span">Serve</Text> platform for listing and booking On-Demand Services (read more about our various platforms in our <Link href="https://bit.ly/youwhowp" isExternal color="primary.500">whitepaper</Link>), <Text as="span" fontWeight="500">YOU</Text> tokens will be launched on the Binance Smart Chain and token holders will be able to withdraw their <Text as="span" fontWeight="500">YOU</Text> tokens from <Text as="span" className="logo-font" fontWeight="300" >Super<Text as="span" fontWeight="500" >Staker</Text></Text>.
                                <br /><br />
                                Our current milestone for the beta launch of <Text className="alt-font" fontWeight="500" as="span">Serve</Text> is q2 of 2022, subsequently the ability to withdraw <Text as="span" fontWeight="500">YOU</Text> tokens will be around the same time.
                            </>
                        }
                    />
                    <Box id="users">
                        <AccItem

                            accHeading={
                                <>
                                    <Text fontWeight="500" fontSize="xl">What is a User / Customer / Vendor / Contributor?</Text>
                                </>
                            }

                            accContent={
                                <>
                                    <List spacing={3} >
                                        <ListItem>
                                            <ListIcon as={CgI.CgUserlane} color="primary.500" fontSize="20px" />
                                            A <Text as="span" fontWeight="500">User</Text> is anyone that has an account in the <Text as="span" className="logo-font" fontSize="lg">youwho</Text> ecosystem. Users can be Customers, Vendors, and/or Contributors. Note that a single User account can be all 3 categories at the same time. It is simply up to the User to decide how they use their account.
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon as={MdI.MdAddShoppingCart} color="primary.500" fontSize="22px" />
                                            <Text as="span" fontWeight="500">Customers</Text> are Users who buy products and/or book services listed by Vendors. Every time the Customer pays for a product or service they will be rewarded with <Text as="span" fontWeight="500">YOU</Text> tokens after they have submitted a review for that product or service.
                                            <br />
                                            Because the <Text as="span" className="logo-font" fontSize="lg">youwho</Text> ecosystem will be self governing, User ratings are critical for maintaining a safe and trustworthy environment, and the best way we feel to acheive this is to offer incentives for Customers to leave reviews of their Vendors after every completed transaction.
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon as={AiI.AiOutlineShop} color="primary.500" fontSize="22px" />
                                            <Text as="span" fontWeight="500">Vendors</Text> are Users that offer products and/or services for Customers to purchase or book. Vendors will have the opportunity to redeem <Text as="span" fontWeight="500">YOU</Text> token rewards following the completion of every transaction by the Customer. The Vendor will be given the opportunity to review the Customer and at the same time receive <Text as="span" fontWeight="500">YOU</Text> token rewards after they have successfully submitted their review.
                                            <br />
                                            Because the <Text as="span" className="logo-font" fontSize="lg">youwho</Text> ecosystem will be self governing, User ratings are critical for maintaining a safe and trustworthy environment, and the best way we feel to acheive this is to offer incentives for Vendors to leave reviews of their Customers after every completed transaction.
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon as={BiI.BiWrench} color="primary.500" fontSize="22px" />
                                            <Text as="span" fontWeight="500">Contributors</Text> are Users who participate in developing, managing, policing and various other tasks in the <Text as="span" className="logo-font" fontSize="lg">youwho</Text> ecosystem. Contributors will be rewarded with <Text as="span" fontWeight="500">YOU</Text> tokens for their participation in various roles. Examples of roles Contributors may choose to take on include but are not limited to: Developers, Verifiers, Arbitrators, and Community Managers. More information will be released regarding the various Contributor roles available as development of the <Text as="span" className="logo-font" fontSize="lg">youwho</Text> ecosystem progresses.
                                        </ListItem>
                                    </List>
                                </>
                            }
                        />
                    </Box>

                    <AccItem
                        accHeading={
                            <>
                                <Text fontWeight="500" fontSize="xl">Where can I register to be a User / Customer / Vendor / Contributor?</Text>
                            </>
                        }

                        accContent={
                            <>
                                Users and Customers can access early bird sign up and earn sweet rewards at our SuperStaker portal by visiting: <Link href="https://ss.youwho.io" isExternal color="primary.500">User/Customer Signup</Link>
                                <br /><br />
                                Vendors and Contributors can gain early beta access and earn rewards for participating at our SuperStaker onboarding portal by visiting: <Link href="https://ss.youwho.io/onboard" isExternal color="primary.500">Vendor/Contributor Signup</Link>
                            </>
                        }
                    />

                    <AccItem
                        accHeading={
                            <>
                                <Text fontWeight="500" fontSize="xl">What is a Verified User?</Text>
                            </>
                        }

                        accContent={
                            <>
                                <Text as="span" fontWeight="500">Verified Users</Text> are Users who have submitted necessary identification, such as a national ID card or Passport photo and in some cases proof of address, to prove who they are.
                                <br /><br />
                                Why you would want to become a <Text as="span" fontWeight="500">Verified User</Text> is so that other Users in the ecosystem feel more confident and comfortable dealing with you because they know you are not anonymous.
                                <br /><br />
                                Furthermore if you want to become a <Text as="span" fontWeight="500">Contributor</Text> in the ecosystem you must verify your account as part of the onboarding process. This is because as a <Text as="span" fontWeight="500">Contributor</Text> you have special priviledges and authorization which has the power to affect other Users, so it is imperative we know who you are to ensure the ecosystem remains trustworthy.
                            </>
                        }
                    />

                    <AccItem
                        accHeading={
                            <>
                                <Text fontWeight="500" fontSize="xl">Where can I learn more about <Text as="span" className="logo-font">youwho</Text> ?</Text>
                            </>
                        }

                        accContent={
                            <>
                                You can learn more about the <Text as="span" className="logo-font">youwho</Text> project and ecosystem by visiting our website: <Link href="https://youwho.io" isExternal color="primary.500">youwho.io</Link>, reading our <Link href="https://bit.ly/youwhowp" isExternal color="primary.500">lightpaper</Link>, or keep in touch through our <Link href="https://why.youwho.io/#contactus" isExternal color="primary.500">various social media handles</Link>.
                            </>
                        }
                    />

                </Accordion>

            </Flex>
        </Flex >
    )
}
