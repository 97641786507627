const fonts = {
  //   body: "'Montserrat', sans-serif",
  heading: "'Poppins', sans-serif",
  body: "'Poppins', sans-serif",
  //   heading: "'Montserrat Alternates', sans-serif",
  //   body: "inter, sans-serif",
  // heading: "inter, sans-serif",
  // mono: "inter, sans-serif",
};

export default fonts;
