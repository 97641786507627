import { React, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useMoralis } from "react-moralis";

import "./css/App.css";

import Home from "./components/pages/Home";
import Request from "./components/forms/Request";
import Onboard from "./components/forms/Onboard";
import Main from "./components/pages/Main";
import FaqPub from "./components/pages/FaqPub";
import MaintenanceMessage from "./components/maintenance/MaintenanceMessage";

function App() {
  const { user, isAuthenticated } = useMoralis();
  const [maintenance, setMaintenance] = useState(false);

  if (maintenance) {
    return <MaintenanceMessage />;
  }

  return (
    <>
      <Router>
        <Switch>
          {isAuthenticated && user && (
            <Route path="/main">
              <Main />
            </Route>
          )}
          <Route path="/request">
            <Request />
          </Route>
          <Route path="/onboard">
            <Onboard />
          </Route>
          <Route path="/faq">
            <FaqPub />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
