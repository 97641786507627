import { React, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Text, Spinner, Heading, Center, Flex, Box, Stack, Button, Icon, InputGroup, InputLeftElement, FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { useMoralis } from "react-moralis";
import { useFormik } from 'formik'
import * as yup from 'yup'
import Web3 from 'web3';

import * as Io5 from 'react-icons/io5'
import * as BiI from 'react-icons/bi'

import YouWho from '../../abis/YouWho.json';
import UhuToken from '../../abis/UhuToken.json';

export default function Buy() {

    const { Moralis, user, refetchUserData, authenticate } = useMoralis();
    const [underConstruction, setUnderConstruction] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pwds, setPwds] = useState("")
    const [encPwds, setEncPwds] = useState("")
    const [decPwds, setDecPwds] = useState("")
    const [uhu, setUhu] = useState("")

    const loadBlockchainData = async () => {

        if (typeof window.ethereum !== 'undefined') {

            const web3 = new Web3(window.ethereum);
            const netId = await web3.eth.net.getId();
            const accounts = await web3.eth.getAccounts();

            // console.log("accounts",accounts);
            // console.log("netId",netId);

            try {

                const options = {
                    contractAddress: UhuToken.networks[netId].address,
                    functionName: "cap",
                    abi: UhuToken.abi,
                    params: {

                    },
                };

                console.log("chain id", await Moralis.getChainId())

                const uhuC = new web3.eth.Contract(UhuToken.abi, UhuToken.networks[netId].address);
                setUhu(uhuC);
                console.log(uhuC)

                const capAbi = await uhuC.methods.cap().encodeABI();
                console.log("capAbi", capAbi)

                const currUhuCCap = await uhuC.methods.cap().call({ from: accounts[0] });
                console.log(currUhuCCap / 10 ** 18)

                const allowance = await Moralis.executeFunction(options);
                console.log("allowance", allowance)
                // // console.log("myUhusdAllowance", currUhusdAllowance);

                // const uhuDappC = new web3.eth.Contract(YouWhoDapp.abi, YouWhoDapp.networks[netId].address);
                // setUhuDapp(uhuDappC);
                // // console.log('uhuDapp contract: ', uhuDappC);

                // await uhuDappC.methods.tokenMapping(web3.utils.asciiToHex(crypto)).call({ from: account }).then(async (result) => {
                //     setCryptoDecimals(result["manualOracleDecimals"]);
                //     const aggregatorV3InterfaceABI = [{ "inputs": [], "name": "decimals", "outputs": [{ "internalType": "uint8", "name": "", "type": "uint8" }], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "description", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "uint80", "name": "_roundId", "type": "uint80" }], "name": "getRoundData", "outputs": [{ "internalType": "uint80", "name": "roundId", "type": "uint80" }, { "internalType": "int256", "name": "answer", "type": "int256" }, { "internalType": "uint256", "name": "startedAt", "type": "uint256" }, { "internalType": "uint256", "name": "updatedAt", "type": "uint256" }, { "internalType": "uint80", "name": "answeredInRound", "type": "uint80" }], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "latestRoundData", "outputs": [{ "internalType": "uint80", "name": "roundId", "type": "uint80" }, { "internalType": "int256", "name": "answer", "type": "int256" }, { "internalType": "uint256", "name": "startedAt", "type": "uint256" }, { "internalType": "uint256", "name": "updatedAt", "type": "uint256" }, { "internalType": "uint80", "name": "answeredInRound", "type": "uint80" }], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "version", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }];
                //     const addr = result["oracleAddress"];
                //     const priceFeed = new web3.eth.Contract(aggregatorV3InterfaceABI, addr);
                //     priceFeed.methods.latestRoundData().call()
                //         .then((roundData) => {
                //             console.log("Latest Price", roundData["answer"] / 10 ** cryptoDecimals);
                //             setCryptoPrice(Number(roundData["answer"] / 10 ** cryptoDecimals).toFixed(2));
                //             setSiteIsLoading3(false);
                //         });
                // });

            } catch (e) {
                console.log('Error', e);
                window.alert('Contract not deployed to the current network');
            }

        } else {
            await authenticate();
            // setEthAddress(user.attributes.ethAddress);
            // window.alert('Please install MetaMask');
        }

    }

    const validationSchema = yup.object().shape({
        username: yup.string().min(3, "Username must be atleast 3 characters long.").max(30, "Username must be 30 characters or less.").required("Username is required."),
        email: yup.string().email("Please enter a valid email.").required("Email is required."),
        // password: yup.string().required("Password is required.").matches(
        //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        //     "Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character."
        // ),
        // passwordConfirm: yup.string().required("Password confirmation is required.").oneOf([yup.ref('password'), null], 'Passwords must match.')
    })

    const formik = useFormik({
        initialValues: {
            username: "",
            email: "",
        },
        onSubmit: (values) => {
            handleRequest(values);
        },
        validationSchema
    })

    const encryptPwd = async (pwd) => {
        let result = await Moralis.Cloud.run("encryptPwd", { pwd });
        setEncPwds(result)
        // console.log("encPwds", result)
    }

    const decryptPwd = async () => {
        await refetchUserData();
        let pwd = user.get("pwd");
        try {
            let result = await Moralis.Cloud.run("decryptPwd", { pwd });
            setDecPwds(result)
            // console.log("decPwds", result)
        } catch (e) {
            console.error(e)
        }
    }

    const handleRequest = async (values) => {
        await Moralis.Cloud.run("sendWelcomeEmail", { email: values.email, name: values.username });
        formik.setSubmitting(false);
        setIsLoading(false) // remove this line if not required
    }

    if (isLoading) {
        return (
            <Center w="100%" h="50vh" flexDir="column">
                <Spinner size="xl" />
                <br />
                <Text className="logo-font" fontSize="2xl">Loading ...</Text>
            </Center>
        )
    }

    if (underConstruction) {
        return (
            <Center as={NavLink} to="/main/stake" maxW="600px" p={8} mt="10vh" mx={[4, "auto", "auto"]} flexDir="column" bg={["", "white", ""]} borderRadius="lg" _hover={{ bg: "#f9f9f9" }} boxShadow={["", "md", ""]} border={["", "1px", ""]} borderColor={["", "gray.100", "gray.100"]}>
                <Icon as={Io5.IoCodeSlash} fontSize="60px" color="primary.500" /><br />
                <Text fontSize="xl" align="center"><Text as="span" fontSize="3xl" fontWeight="500">Check Back Soon</Text><br />Buy section is currently under construction.<br />Please check back soon.</Text>
            </Center>
        )
    }

    return (
        <Flex minH="50vh" w="100%" p={[0, 2, 2]} alignItems="flex-start" flexWrap="wrap">

            <Flex
                minH="400px"
                bg="white"
                maxW="600px"
                boxShadow={[0, "md", "md"]}
                flexDir="column"
                borderRadius={[0, "lg", "lg"]}
                mx="auto"
                flexGrow="1"
                border="1px" borderColor="gray.100"
            >
                <Box
                    pt="24px"
                    px={6}
                    pb={5}
                    display="inline-flex"
                    alignItems="center"
                    // bg="#f5f5f5"
                    borderTopRadius={[0, "lg", "lg"]}
                >
                    <Icon as={Io5.IoReaderOutline} fontSize={["3xl", "4xl", "4xl"]} mr={4} color="primary.500" /><Text fontSize={["2xl", "2xl", "2xl"]} fontWeight="400">Purchase Request</Text>
                </Box>

                <Stack spacing="6" p="8">
                    <Input type="text" onChange={e => setPwds(e.target.value)} />
                    <Button onClick={loadBlockchainData} >get uhu cap</Button>
                    <Button onClick={async () => { user.set("vendor", true); await user.save(); console.log("user updated") }} >save user</Button>
                    <Button onClick={() => decryptPwd()} >decrypt</Button>

                    <form onSubmit={formik.handleSubmit}>

                        <Stack>
                            <Text>Email</Text>
                            <Text>Full Name</Text>
                            <Text>Please select the USD amount you would like to invest</Text>
                            <Text>Please select the ERC20 USD Stablecoin you would like to send payments in</Text>
                            <Text>Enter a 4 digit pin used to verify email correspondence</Text>
                            <Text>Please read and tick all of the boxes below to proceed with registration</Text>
                            <FormControl isInvalid={formik.touched.username && formik.errors.username} >
                                <InputGroup>
                                    <InputLeftElement children={<Icon as={BiI.BiUser} mt="8px" w="20px" h="20px" />} />
                                    <Input
                                        focusBorderColor="primary.500"
                                        variant="flushed"
                                        size="lg"
                                        id="username"
                                        type="name"
                                        placeholder="Username"
                                        aria-label="Username"
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                        fontWeight="300"
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.touched.username && formik.errors.username}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={formik.touched.email && formik.errors.email} >
                                <InputGroup>
                                    <InputLeftElement children={<Icon as={BiI.BiEnvelope} mt="8px" w="20px" h="20px" />} />
                                    <Input
                                        focusBorderColor="primary.500"
                                        variant="flushed"
                                        size="lg"
                                        id="email"
                                        type="email"
                                        placeholder="Email"
                                        aria-label="Email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        fontWeight="300"
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.touched.email && formik.errors.email}</FormErrorMessage>
                            </FormControl>


                            <Button type="submit" isLoading={formik.isSubmitting} variant="ghost" fontWeight="500" size="lg" fontSize="xl">Submit Request</Button>

                        </Stack>
                    </form>
                </Stack>

            </Flex>

        </Flex >
    )
}
