import { React, useState } from 'react'
import { Box, Image, Input, IconButton, FormControl, FormErrorMessage, FormHelperText, FormLabel, Flex } from '@chakra-ui/react'
import "./style.css"

import * as RiI from 'react-icons/ri'

export default function ImageUploader({ setFile, imageSize, label, helper, isRequired }) {

    const [fileSrc, setFileSrc] = useState("")
    const [fileError, setFileError] = useState("")
    const fileEvent = document.getElementById("file");

    // useEffect(() => {
    //     if (!fileEvent?.value) {
    //         setFile(null);
    //         setFileSrc("")
    //         setFileError("")
    //     }
    //     // eslint-disable-next-line
    // }, [fileEvent?.value])

    const onInputChange = (e) => {
        if (e.target?.files[0]?.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            if (e.target?.files[0]?.size <= imageSize) {
                setFileError("");
                setFile(e.target.files[0])
                setFileSrc(URL.createObjectURL(e.target.files[0]))
            } else {
                setFileError(`Image size is too large, please select an image that is less than or equal to ${imageSize / 1024}kb in size.`);
            }
        } else {
            setFileError("Wrong image file type, please select select an image of file type 'jpg', 'jpeg', 'png', or 'gif'.");
        }
    };

    const handleClear = () => {
        setFile(null);
        setFileSrc("");
        fileEvent.value = ""
    }

    return (
        <FormControl isInvalid={fileError} isRequired={isRequired} className="required-gray">
            <FormLabel m={0}>{label}</FormLabel>
            <FormHelperText m={0} mb={3}>{helper}</FormHelperText>
            {fileSrc ?
                <Flex alignItems="flex-end" p={2}>
                    <Box className="img-preview" >
                        <Image src={fileSrc} alt="Image preview" style={{ height: "150px" }} />
                    </Box>
                    <IconButton icon={<RiI.RiDeleteBin2Line />} onClick={handleClear} variant="unstyled" fontSize="30px" color="gray.600" ml={3} />
                </Flex>
                :
                <Box className="form-group files">
                    <Input
                        id="file"
                        type="file"
                        onChange={onInputChange}
                        className="form-control"
                        multiple=""
                    />
                    <label htmlFor="file" className="input"></label>
                </Box>
            }
            <FormErrorMessage>{fileError}</FormErrorMessage>
        </FormControl>
    )
}
