import { React } from 'react'
import { Text, Link, Center } from "@chakra-ui/react";

export default function Footer() {
    return (
        <Center
            marginTop="auto"
            w="100%"
            bg="#e9e9e9"
            as={Link} href="https://youwho.io"
            _hover={{ fontWeight: "600", textDecoration: "none" }}
            isExternal
        >
            <Text className="logo-font" color="gray" _hover={{ fontWeight: "500", textDecoration: "none" }} p={1}>youwho</Text>
        </Center>
    )
}
