import { React, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Text, Center, Spinner } from "@chakra-ui/react";
import { useMoralis } from 'react-moralis';

import Onboard2 from '../forms/Onboard2';
import OnboardProfile from '../forms/OnboardProfile';
import ProfileMain from '../forms/ProfileMain';

export default function Profile({ gravatarUrl, gravatarProfileUrl }) {

    const { Moralis, user } = useMoralis();
    const [continueOnboarding, setContinueOnboarding] = useState(false)
    const [registerVendorContributor, setRegisterVendorContributor] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [vendor, setVendor] = useState(false)
    const [contributor, setContributor] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                const queryOnboarding = new Moralis.Query("OnboardRegister");
                queryOnboarding.equalTo("user", user);
                queryOnboarding.equalTo("status", "pending");
                const objectOnboarding = await queryOnboarding.first();
                setContinueOnboarding(objectOnboarding ? true : false);
                setVendor(objectOnboarding?.attributes?.vendor ? true : false);
                setContributor(objectOnboarding?.attributes?.contributor ? true : false)
            } catch (e) {
                console.error("error querying onboarding", e)
            }
            setIsLoading(false);
        })()
        // eslint-disable-next-line
    }, [])

    if (isLoading) {
        return (
            <Center w="100%" h="50vh" flexDir="column">
                <Spinner size="xl" />
                <br />
                <Text fontSize="xl">Loading ...</Text>
            </Center>
        )
    }

    return (
        <Switch>
            {continueOnboarding &&
                <Route path="/main/profile">
                    <Onboard2 vendor={vendor} contributor={contributor} />
                </Route>
            }
            {registerVendorContributor &&
                <Route path="/main/profile">
                    <OnboardProfile />
                </Route>
            }
            <Route path="/main/profile">
                <ProfileMain gravatarUrl={gravatarUrl} gravatarProfileUrl={gravatarProfileUrl} />
            </Route>
        </Switch>
    )
}
