import { React } from "react";
import { Text, Link, Center, Box } from "@chakra-ui/react";

import YouwhoLogo from "../../media/logo/YouwhoLogo";

export default function MaintenanceMessage() {
    return (
        <Center w="100vw" h="100vh">
            <Box
                w={["100vw", "400px", "400px"]}
                p={8}
                boxShadow={["", "md", "md"]}
                rounded="lg"
                bg={["", "white", "white"]}
                mb="30vh"
            >
                <Box>
                    <Center>
                        <Link href="https://youwho.io" isExternal>
                            <YouwhoLogo wdth="60px" />
                        </Link>
                    </Center>
                    <Box align="center">
                        <Text className="logo-font" fontWeight="300" fontSize="3xl">
                            Super
                            <Text as="span" fontWeight="500">
                                Staker
                            </Text>
                        </Text>
                        <Text>
                            <br />
                            Undergoing maintenance
                            <br />
                            Please check back soon ...
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Center>
    )
}


