const styles = {
  global: (props) => ({
    // styles for the `body`
    "html, body": {
      // background: props.colorMode === "dark" ? "gray.900" : "gray.50",
      // eslint-disable-next-line
      background: [
        "#ffffff",
        "radial-gradient(circle at 50vw 70vh, #ffffff 0%, #f9f9f9 70%)",
        "radial-gradient(circle at 50vw 70vh, #ffffff 0%, #f9f9f9 70%)",
      ],
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      overflowX: "hidden",
      fontWeight: "300",
    },
    a: {
      // color: props.colorMode === "dark" ? "teal.300" : "teal.500",
    },
    // styles for the `a`
    // a: {
    //     color: "teal.500",
    //     _hover: {
    //         textDecoration: "underline",
    //     },
    // },
  }),
};

export default styles;
