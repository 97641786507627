import { React } from 'react'
import { Text, Center, Flex, Button, Spacer, Icon } from "@chakra-ui/react";
import { useMoralis } from "react-moralis";

import * as Io5 from 'react-icons/io5'

export default function Header({ username }) {

    const { logout } = useMoralis();

    return (

        <Flex justify="space-between" p={4}>

            <Flex w="100px" display={["none", "flex", ""]} ></Flex>

            <Spacer />

            <Flex>
                <Text align="center" fontSize="lg">Welcome <Text as="span" fontWeight="500">{username} </Text>to<br />
                    <Text className="logo-font" fontWeight="300" fontSize="4xl" as="span"  >Super<Text as="span" fontWeight="500" >Staker</Text></Text>
                </Text>
            </Flex>

            <Spacer />

            <Flex mr={2} display={["none", "flex", ""]}>
                <Center _hover={{ color: "gray" }} >
                    <Button onClick={logout} variant="unstyled" d="inline-flex"><Text mr={2} >Logout</Text><Icon as={Io5.IoLogOutOutline} w="20px" h="20px" /></Button>
                </Center>
            </Flex>

        </Flex>

    )
}