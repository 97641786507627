import { React, useState, useEffect } from "react";
import { useMoralis } from "react-moralis";
import {
  useDisclosure,
  Link,
  IconButton,
  Avatar,
  useToast,
  Box,
  Center,
  Flex,
  Stack,
  Input,
  InputGroup,
  Icon,
  InputLeftElement,
  FormLabel,
  InputRightElement,
  FormControl,
  Button,
  FormErrorMessage,
  FormHelperText,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { copyTextToClipboard } from "../../js/utils";
import * as yup from "yup";

import * as Io5 from "react-icons/io5";
import * as BiI from "react-icons/bi";

import ConfirmModalNoButton from "../modal/ConfirmModalNoButton";

export default function ProfileMain({ gravatarUrl, gravatarProfileUrl }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    setUserData,
    userError,
    user,
    Moralis,
    logout,
    refetchUserData,
    isAuthenticated,
  } = useMoralis();
  const [username, setUsername] = useState(user.attributes?.username);
  const [userId, setUserId] = useState(user?.id);
  const [usernameConfirm, setUsernameConfirm] = useState(false);
  const [email, setEmail] = useState(user.attributes?.email);
  const [emailVerified, setEmailVerified] = useState(
    user.attributes?.emailVerified
  );
  const [userSuperStaker, setUserSuperStaker] = useState("");
  const [ethAddress, setEthAddress] = useState(user.attributes?.ethAddress);

  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is required."),
  });

  const passwordSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character."
      ),
    passwordConfirm: yup
      .string()
      .required("Password confirmation is required.")
      .oneOf([yup.ref("password"), null], "Passwords must match."),
  });

  const usernameSchema = yup.object().shape({
    username: yup
      .string()
      .min(3, "Username must be atleast 3 characters long.")
      .max(30, "Username must be 30 characters or less.")
      .required("Username is required."),
  });

  useEffect(() => {
    refetchUserData();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const UserSuperStaker = Moralis.Object.extend("UserSuperStaker");
          let queryUserSuperStaker = new Moralis.Query(UserSuperStaker);
          queryUserSuperStaker.equalTo("user", user);
          let userSuperStakerRes = await queryUserSuperStaker.first();
          setUserSuperStaker(userSuperStakerRes.attributes);
        } catch (e) {
          console.error("error from getting userSuperStaker", e);
        }
      })();
    }
  }, [isAuthenticated]);

  const formikEmail = useFormik({
    initialValues: {
      email: email,
    },
    onSubmit: (values) => {
      handleVerifyEmail(values);
    },
    validationSchema: emailSchema,
  });

  const handleVerifyEmail = async (values) => {
    if (values.email) {
      try {
        await setUserData({ email: values.email });
        toast({
          title: "Success.",
          description: (
            <>
              We have sent an email to <b>{user.attributes.email}</b> . Please
              follow the link to verify your email. Then logout and log back in.
            </>
          ),
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        logout();
      } catch (error) {
        toast({
          title: "Error.",
          description: <>{error.message}</>,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    formikEmail.setSubmitting(false);
  };

  const handleReverifyEmail = async () => {
    try {
      await Moralis.User.requestEmailVerification(email);
      toast({
        title: "Success.",
        description: (
          <>
            We have sent an email to <b>{user.attributes.email}</b> . Please
            logout, follow the link in your email to verify your email, then log
            back in.
          </>
        ),
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error.",
        description: <>{error.message}</>,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const formikUsername = useFormik({
    initialValues: {
      username: username,
    },
    onSubmit: (values) => {
      setUsername(values.username);
      onOpen();
    },
    validationSchema: usernameSchema,
  });

  useEffect(() => {
    (async () => {
      if (usernameConfirm) {
        try {
          await setUserData({ username });
          logout();
        } catch (e) {
          console.error("error from update username", e);
        }
        formikUsername.setSubmitting(false);
      }
    })();
  }, [usernameConfirm]);

  const formikPassword = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    onSubmit: (values) => {
      handlePasswordUpdate(values);
    },
    validationSchema: passwordSchema,
  });

  const handlePasswordUpdate = async (values) => {
    try {
      await setUserData({ password: values.password });
      await Moralis.Cloud.run("encryptPwd", { pwd: values.password });
      toast({
        title: "Success.",
        description: (
          <>
            Your password has been updated. Use your new password the next time
            you login.
          </>
        ),
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error.",
        description: <>{error.message}</>,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      logout();
    }
    formikPassword.resetForm({});
    formikPassword.setSubmitting(false);
  };

  return (
    <Flex
      minH="50vh"
      w="100%"
      p={[0, 4, 4]}
      alignItems="flex-start"
      flexWrap="wrap"
    >
      <Flex
        minH="400px"
        bg="white"
        maxW="600px"
        boxShadow={[0, "md", "md"]}
        flexDir="column"
        borderRadius={[0, "lg", "lg"]}
        mx="auto"
        flexGrow="1"
        border="1px"
        borderColor="gray.100"
      >
        <Box
          pt="24px"
          px={6}
          pb={5}
          display="inline-flex"
          alignItems="center"
          // bg="#f5f5f5"
          borderTopRadius={[0, "lg", "lg"]}
        >
          <Icon
            as={Io5.IoOptions}
            fontSize={["3xl", "4xl", "4xl"]}
            mr={4}
            color="primary.500"
          />
          <Text fontSize={["2xl", "2xl", "2xl"]} fontWeight="400">
            Profile Settings
          </Text>
        </Box>
        <Center>
          <Link
            href={gravatarProfileUrl ? gravatarProfileUrl : "#avatar"}
            isExternal={gravatarProfileUrl ? true : false}
            className="no-text-decor"
          >
            <Avatar
              size="2xl"
              src={gravatarProfileUrl ? gravatarUrl : ""}
              name={username}
            />
          </Link>
        </Center>
        <Stack spacing="8" p="8">
          <Flex
            alignItems="center"
            borderBottom="1px"
            borderColor="gray.200"
            pb="4px"
          >
            <Icon
              as={BiI.BiUserPin}
              w="30px"
              h="30px"
              mr="6px"
              color="gray.600"
            />
            <Text fontSize="20px">User Details</Text>
          </Flex>

          {userId && (
            <FormControl isReadOnly>
              <FormLabel m={0} fontWeight="300">
                User ID (used for referrals)
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  children={<Icon as={BiI.BiIdCard} w="20px" h="20px" />}
                />
                <Input
                  mt="6px"
                  focusBorderColor="primary.500"
                  variant="unstyled"
                  size="lg"
                  id="userId"
                  type="text"
                  placeholder={userId}
                  aria-label="User ID"
                  value={userId}
                  fontWeight="500"
                />
                <InputRightElement
                  as="button"
                  title="copy ID"
                  children={<Icon as={BiI.BiLinkAlt} w="20px" h="20px" />}
                  onClick={() => {
                    copyTextToClipboard(userId);
                    toast({
                      title: `Copied`,
                      description: `${userId}`,
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                />
              </InputGroup>
            </FormControl>
          )}

          {ethAddress && (
            <FormControl isReadOnly>
              <FormLabel m={0} fontWeight="300">
                Eth Address
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  children={<Icon as={BiI.BiWallet} w="20px" h="20px" />}
                />
                <Input
                  mt="6px"
                  focusBorderColor="primary.500"
                  variant="unstyled"
                  size="lg"
                  id="ethAddress"
                  type="text"
                  placeholder={ethAddress}
                  aria-label="ethAddress"
                  value={ethAddress}
                  fontWeight="500"
                />
              </InputGroup>
            </FormControl>
          )}

          {email ? (
            <FormControl isReadOnly>
              <FormLabel m={0} fontWeight="300">
                Email {emailVerified ? "(verified)" : "(not verified)"}
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  children={<Icon as={BiI.BiEnvelope} w="20px" h="20px" />}
                />
                <Input
                  mt="6px"
                  focusBorderColor="primary.500"
                  variant="unstyled"
                  size="lg"
                  id="email"
                  type="email"
                  placeholder={email}
                  aria-label="Email"
                  value={email}
                  fontWeight="500"
                />
              </InputGroup>
              {emailVerified ? (
                ""
              ) : (
                <FormHelperText
                  align="left"
                  as="Button"
                  color="gray"
                  onClick={handleReverifyEmail}
                  _hover={{ color: "#ff3000" }}
                  fontSize="12px"
                >
                  Resend Email Verification Link
                </FormHelperText>
              )}
            </FormControl>
          ) : (
            <form onSubmit={formikEmail.handleSubmit}>
              <FormControl
                isInvalid={
                  formikEmail.touched.email && formikEmail.errors.email
                }
              >
                <FormLabel m={0} fontWeight="400">
                  Email
                </FormLabel>
                <FormHelperText m={0}>
                  Verify your email address and earn UHU token rewards.
                </FormHelperText>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Icon as={BiI.BiEnvelope} mt="8px" w="20px" h="20px" />
                    }
                  />
                  <Input
                    focusBorderColor="primary.500"
                    variant="flushed"
                    size="lg"
                    id="email"
                    type="email"
                    placeholder="Email"
                    aria-label="Email"
                    value={formikEmail.values.email}
                    onChange={formikEmail.handleChange}
                    fontWeight="500"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {formikEmail.touched.email && formikEmail.errors.email}
                </FormErrorMessage>
              </FormControl>
              <Button
                w="100%"
                mt="6"
                type="submit"
                isLoading={formikEmail.isSubmitting}
                variant="outline"
                fontWeight="500"
                size="lg"
                fontSize="xl"
              >
                Verify Email
              </Button>
            </form>
          )}

          {email && (
            <>
              {userSuperStaker.updatedUsername !== 0 ? (
                <FormControl isReadOnly>
                  <FormLabel m={0} fontWeight="300">
                    Username
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={<Icon as={BiI.BiUser} w="20px" h="20px" />}
                    />
                    <Input
                      mt="6px"
                      focusBorderColor="primary.500"
                      variant="unstyled"
                      size="lg"
                      id="username"
                      type="text"
                      placeholder={username}
                      aria-label="Username"
                      value={username}
                      fontWeight="500"
                    />
                  </InputGroup>
                </FormControl>
              ) : (
                <form onSubmit={formikUsername.handleSubmit}>
                  <FormControl
                    isInvalid={
                      (formikUsername.touched.username &&
                        formikUsername.errors.username) ||
                      userError
                    }
                  >
                    <FormLabel m={0} fontWeight="400">
                      Username
                    </FormLabel>
                    <FormHelperText m={0}>
                      Used to uniquely identify you in the{" "}
                      <Text as="span" className="logo-font">
                        youwho
                      </Text>{" "}
                      ecosystem.
                      <br />
                      Your Username can only be changed once.
                    </FormHelperText>
                    <InputGroup mt={1}>
                      <InputLeftElement
                        children={
                          <Icon as={BiI.BiUser} mt="8px" w="20px" h="20px" />
                        }
                      />
                      <Input
                        focusBorderColor="primary.500"
                        variant="flushed"
                        size="lg"
                        id="username"
                        type="text"
                        placeholder="Username"
                        aria-label="Username"
                        value={formikUsername.values.username}
                        onChange={formikUsername.handleChange}
                        fontWeight="500"
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Update username"
                          icon={<Io5.IoSaveOutline fontSize="22px" />}
                          variant="ghost"
                          type="submit"
                          isLoading={formikUsername.isSubmitting}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {(formikUsername.touched.username &&
                        formikUsername.errors.username) ||
                        userError?.message}
                    </FormErrorMessage>
                  </FormControl>
                </form>
              )}
              <form onSubmit={formikPassword.handleSubmit}>
                <Stack spacing="4">
                  <FormControl
                    isInvalid={
                      formikPassword.touched.password &&
                      formikPassword.errors.password
                    }
                  >
                    <FormLabel m={0} fontWeight="300">
                      New Password
                    </FormLabel>
                    {/* <FormHelperText m={0}>Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character.</FormHelperText> */}
                    <InputGroup mt={1}>
                      <InputLeftElement
                        children={
                          <Icon as={BiI.BiKey} mt="8px" w="20px" h="20px" />
                        }
                      />
                      <Input
                        focusBorderColor="primary.500"
                        variant="flushed"
                        size="lg"
                        id="password"
                        type="password"
                        placeholder="Password"
                        aria-label="Password"
                        value={formikPassword.values.password}
                        onChange={formikPassword.handleChange}
                        fontWeight="300"
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {formikPassword.touched.password &&
                        formikPassword.errors.password}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      formikPassword.touched.passwordConfirm &&
                      formikPassword.errors.passwordConfirm
                    }
                  >
                    <FormLabel m={0} fontWeight="300">
                      Confirm New Password
                    </FormLabel>
                    {/* <FormHelperText m={0}>Repeat the password entered above.</FormHelperText> */}
                    <InputGroup mt={1}>
                      <InputLeftElement
                        children={
                          <Icon as={BiI.BiKey} mt="8px" w="20px" h="20px" />
                        }
                      />
                      <Input
                        focusBorderColor="primary.500"
                        variant="flushed"
                        size="lg"
                        id="passwordConfirm"
                        type="password"
                        placeholder="Confirm password"
                        aria-label="Confirm password"
                        value={formikPassword.values.passwordConfirm}
                        onChange={formikPassword.handleChange}
                        fontWeight="300"
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {formikPassword.touched.passwordConfirm &&
                        formikPassword.errors.passwordConfirm}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    type="submit"
                    isLoading={formikPassword.isSubmitting}
                    variant="ghost"
                    fontWeight="500"
                    size="lg"
                    fontSize="xl"
                  >
                    Update Password
                  </Button>
                </Stack>
              </form>
            </>
          )}
        </Stack>

        <ConfirmModalNoButton
          isOpen={isOpen}
          onClose={onClose}
          confirmButtonText={"Confirm"}
          handleConfirm={setUsernameConfirm}
          modalTitle={"Confirm Username Change"}
          modalMessage={
            "I understand that I will only be able to change my Username once."
          }
          formikUsername={formikUsername}
          modalIcon={BiI.BiBell}
        />
      </Flex>
    </Flex>
  );
}
