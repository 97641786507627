import React from 'react'

import Faq from './Faq'

export default function FaqPub() {
    return (
        <>
            <Faq />
        </>
    )
}
