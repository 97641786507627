import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
import {
  Stack,
  Input,
  Spacer,
  InputGroup,
  Icon,
  IconButton,
  Link,
  InputLeftElement,
  FormControl,
  Button,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Center,
  Text,
  Flex,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { useFormik, Form, Formik, FormikConfig, FormikValues } from "formik";
import * as yup from "yup";

import YouwhoLogo from "../../media/logo/YouwhoLogo";
import * as BiI from "react-icons/bi";
import { object } from "yup/lib/locale";

export default function Onboard() {
  const { user, Moralis, signup, isAuthenticated, authError } = useMoralis();
  const history = useHistory();
  const [friendIdCheck, setFriendIdCheck] = useState("");

  const formik2 = useFormik({
    initialValues: {
      name: "",
      username: "",
      email: "",
      //   password: "",
      //   passwordConfirm: "",
      //   checkboxes: [],
      //   friendId: "",
      typeboxes: [],
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .min(5, "Your full name must be atleast 5 characters long.")
        .required("Your full name is required."),
      username: yup
        .string()
        .min(3, "Username must be atleast 3 characters long.")
        .max(30, "Username must be 30 characters or less.")
        .required("Username is required."),
      email: yup
        .string()
        .email("Please enter a valid email.")
        .required("Email is required."),
      //   password: yup
      //     .string()
      //     .required("Password is required.")
      //     .matches(
      //       /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      //       "Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character."
      //     ),
      //   passwordConfirm: yup
      //     .string()
      //     .required("Password confirmation is required.")
      //     .oneOf([yup.ref("password"), null], "Passwords must match."),
      //   checkboxes: yup.array().min(2, "You must tick all checkboxes."),
      //   friendId: yup
      //     .string()
      //     .min(5, "Friends ID must be atleast 5 characters long.")
      //     .max(100, "Friends ID must be 100 characters or less."),
      typeboxes: yup.array().min(1, "You must tick at least 1 box."),
    }),
    // onSubmit={(values, actions) => {
    //     setTimeout(() => {
    //         alert(JSON.stringify(values, null, 2))
    //         actions.setSubmitting(false)
    //     }, 1000)
    // }}
  });

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .min(5, "Your full name must be atleast 5 characters long.")
      .required("Your full name is required."),
    username: yup
      .string()
      .min(3, "Username must be atleast 3 characters long.")
      .max(30, "Username must be 30 characters or less.")
      .required("Username is required."),
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is required."),
    password: yup
      .string()
      .required("Password is required.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character."
      ),
    passwordConfirm: yup
      .string()
      .required("Password confirmation is required.")
      .oneOf([yup.ref("password"), null], "Passwords must match."),
    checkboxes: yup.array().min(2, "You must tick all checkboxes."),
    friendId: yup
      .string()
      .min(5, "Friends ID must be atleast 5 characters long.")
      .max(100, "Friends ID must be 100 characters or less."),
    typeboxes: yup.array().min(1, "You must tick at least 1 box."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      checkboxes: [],
      friendId: "",
      typeboxes: [],
    },
    onSubmit: (values) => {
      handleRegister(values);
      // console.log("values", values);
      // const { username, password, email, typeboxes, checkboxes } = values;
      // let vendor = "vendor" === typeboxes.find(e => e === "vendor");
      // let contributor = "contributor" === typeboxes.find(e => e === "contributor");
      // console.log("vendor", vendor, "contributor", contributor)
      // formik.setSubmitting(false);
    },
    validationSchema,
  });

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/main/profile");
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const handleRegister = async (values) => {
    const { username, password, email, typeboxes, friendId, checkboxes } =
      values;
    let vendor = "vendor" === typeboxes.find((e) => e === "vendor");
    let contributor =
      "contributor" === typeboxes.find((e) => e === "contributor");

    try {
      const result = await Moralis.Cloud.run("getFriendId", { friendId });
      if (result) {
        setFriendIdCheck("");
        await signup(username, password, email);
        await Moralis.Cloud.run("setOnboardRegister", {
          vendor,
          contributor,
          friendId,
          checkboxes,
        });

        history.push("/main/profile");
      } else {
        setFriendIdCheck(
          "Referral ID not found. Please enter a valid Referral ID."
        );
      }
    } catch (e) {
      console.error("error from onboarding", e);
    }
    formik.setSubmitting(false);
  };

  if (false) {
    return (
      <Center w="100vw" h="100vh">
        <Flex
          minH={["", "400px", ""]}
          maxW="600px"
          bg="white"
          boxShadow={["0", "md", ""]}
          flexDir="column"
          borderRadius={["0", "lg", ""]}
          m={[0, 4, 4]}
          flexGrow={1}
          border={["", "1px", ""]}
          borderColor={["", "gray.100", "gray.100"]}
        >
          <Flex
            pt="24px"
            px={6}
            pb={5}
            borderTopRadius={["0", "lg", ""]}
            borderBottom={["", "1px", ""]}
            borderColor={["", "gray.100", "gray.100"]}
            flexDir="column"
          >
            <Flex alignItems="center">
              <Flex mr={2}>
                <YouwhoLogo wdth="50px" />
              </Flex>
              <Text
                color="gray"
                fontSize={["2xl", "2xl", "2xl"]}
                fontWeight="400"
              >
                Vendor & Contributor Registration
              </Text>
              <Spacer />
              <IconButton
                aria-label="go back"
                icon={<BiI.BiLeftArrowCircle />}
                variant="unstyled"
                fontSize={["3xl", "4xl", "4xl"]}
                color="gray"
                _hover={{ color: "primary.500" }}
                onClick={() => history.push("/")}
              />
            </Flex>
            <Flex px={2} pt={2}>
              <Text color="gray" fontSize="sm">
                Register to be a Vendor and/or Contributor for the{" "}
                <Text as="span" className="logo-font">
                  youwho
                </Text>{" "}
                ecosystem. Vendors and Contributors will be rewarded with{" "}
                <Text as="span" fontWeight="500">
                  YOU
                </Text>{" "}
                tokens for participating in the ecosystem.
                <br />
                <Link href="https://why.youwho.io/#faq" isExternal>
                  Click here to learn more about being a Vendor or Contributor
                </Link>
              </Text>
            </Flex>
          </Flex>

          {/* <form onSubmit={formik2.handleSubmit}> */}
          <FormikStepper formik2={formik2}>
            <Stack spacing={8} p={8}>
              <FormControl
                isInvalid={formik2.touched.name && Boolean(formik2.errors.name)}
              >
                <FormLabel m={0}>1. Full Name *</FormLabel>
                <FormHelperText m={0}>
                  Used for verification purposes for receiving{" "}
                  <Text as="span" fontWeight="500">
                    YOU
                  </Text>{" "}
                  token rewards. You will be asked to provide verification
                  documentation in the next stage.
                </FormHelperText>
                <InputGroup mt={1}>
                  <InputLeftElement
                    children={
                      <Icon as={BiI.BiNews} mt="8px" w="20px" h="20px" />
                    }
                  />
                  <Input
                    focusBorderColor="primary.500"
                    variant="flushed"
                    size="lg"
                    id="name"
                    type="text"
                    placeholder="Full Name"
                    aria-label="Full Name"
                    value={formik2.values.name}
                    onChange={formik2.handleChange}
                    fontWeight="300"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {formik2.touched.name && formik2.errors.name}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik2.touched.username && Boolean(formik2.errors.username)
                }
              >
                <FormLabel m={0}>2. Username *</FormLabel>
                <FormHelperText m={0}>
                  Used to uniquely identify you in the{" "}
                  <Text as="span" className="logo-font">
                    youwho
                  </Text>{" "}
                  ecosystem.
                </FormHelperText>
                <InputGroup mt={1}>
                  <InputLeftElement
                    children={
                      <Icon as={BiI.BiUser} mt="8px" w="20px" h="20px" />
                    }
                  />
                  <Input
                    focusBorderColor="primary.500"
                    variant="flushed"
                    size="lg"
                    id="username"
                    type="text"
                    placeholder="Username"
                    aria-label="Username"
                    value={formik2.values.username}
                    onChange={formik2.handleChange}
                    fontWeight="300"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {formik2.touched.username && formik2.errors.username}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack spacing={8} p={8}>
              <FormControl
                isInvalid={
                  formik2.touched.typeboxes && Boolean(formik2.errors.typeboxes)
                }
              >
                <FormLabel m={0}>
                  6. Tick whether you would like to be a vendor, contributor or
                  both *
                </FormLabel>
                <FormHelperText m={0}>
                  <Link href="https://why.youwho.io/#faq" isExternal>
                    Click here to learn more about being a vendor and/or
                    contributor
                  </Link>
                </FormHelperText>

                <CheckboxGroup colorScheme="red" size="lg">
                  <Stack px={4} mt={4} spacing="3">
                    <Checkbox
                      name="typeboxes"
                      onChange={formik2.handleChange}
                      value="vendor"
                    >
                      <Text>Vendor</Text>
                    </Checkbox>
                    <Checkbox
                      name="typeboxes"
                      onChange={formik2.handleChange}
                      value="contributor"
                    >
                      <Text>Contributor</Text>
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>

                <FormErrorMessage>
                  {formik2.touched.typeboxes && formik2.errors.typeboxes}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <Stack spacing={8} p={8}>
              <FormControl
                isInvalid={
                  formik2.touched.email && Boolean(formik2.errors.email)
                }
              >
                <FormLabel m={0}>3. Email *</FormLabel>
                <FormHelperText m={0}>
                  Used to login to the{" "}
                  <Text as="span" className="logo-font">
                    youwho
                  </Text>{" "}
                  ecosystem. We will never share your email address.
                </FormHelperText>
                <InputGroup mt={1}>
                  <InputLeftElement
                    children={
                      <Icon as={BiI.BiEnvelope} mt="8px" w="20px" h="20px" />
                    }
                  />
                  <Input
                    focusBorderColor="primary.500"
                    variant="flushed"
                    size="lg"
                    id="email"
                    type="email"
                    placeholder="Email"
                    aria-label="Email"
                    value={formik2.values.email}
                    onChange={formik2.handleChange}
                    fontWeight="300"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {formik2.touched.email && formik2.errors.email}
                </FormErrorMessage>
              </FormControl>

              <Button
                mt={4}
                colorScheme="teal"
                // isLoading={formik2.isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </FormikStepper>
          {/* </form> */}
        </Flex>
      </Center>
    );
  }

  return (
    <Center w="100vw">
      <Flex
        minH="400px"
        maxW="600px"
        bg="white"
        boxShadow="md"
        flexDir="column"
        borderRadius={["0", "lg", ""]}
        m={[0, 4, 4]}
        flexGrow={1}
        border="1px"
        borderColor="gray.100"
      >
        <Flex
          pt="24px"
          px={6}
          pb={5}
          borderTopRadius={["0", "lg", ""]}
          borderBottom="1px"
          borderColor="gray.100"
          bg="#f5f5f5"
          flexDir="column"
        >
          <Flex alignItems="center">
            <Flex mr={2}>
              <YouwhoLogo wdth="50px" />
            </Flex>
            <Text
              color="gray"
              fontSize={["2xl", "2xl", "2xl"]}
              fontWeight="400"
            >
              Vendor & Contributor Registration
            </Text>
            <Spacer />
            <IconButton
              aria-label="go back"
              icon={<BiI.BiLeftArrowCircle />}
              variant="unstyled"
              fontSize={["3xl", "4xl", "4xl"]}
              color="gray"
              _hover={{ color: "primary.500" }}
              onClick={() => history.push("/")}
            />
          </Flex>
          <Flex px={2} pt={2}>
            <Text color="gray" fontSize="sm">
              Register to be a Vendor and/or Contributor for the{" "}
              <Text as="span" className="logo-font">
                youwho
              </Text>{" "}
              ecosystem. Vendors and Contributors will be rewarded with{" "}
              <Text as="span" fontWeight="500">
                YOU
              </Text>{" "}
              tokens for participating in the ecosystem.
              <br />
              <Link href="https://why.youwho.io/#faq" isExternal>
                Click here to learn more about being a Vendor or Contributor
              </Link>
            </Text>
          </Flex>
        </Flex>

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={20} p={8}>
            <FormControl
              isInvalid={formik.touched.name && Boolean(formik.errors.name)}
            >
              <FormLabel m={0}>1. Full Name *</FormLabel>
              <FormHelperText m={0}>
                Used for verification purposes for receiving{" "}
                <Text as="span" fontWeight="500">
                  YOU
                </Text>{" "}
                token rewards. You will be asked to provide verification
                documentation in the next stage.
              </FormHelperText>
              <InputGroup mt={1}>
                <InputLeftElement
                  children={<Icon as={BiI.BiNews} mt="8px" w="20px" h="20px" />}
                />
                <Input
                  focusBorderColor="primary.500"
                  variant="flushed"
                  size="lg"
                  id="name"
                  type="text"
                  placeholder="Full Name"
                  aria-label="Full Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  fontWeight="300"
                />
              </InputGroup>
              <FormErrorMessage>
                {formik.touched.name && formik.errors.name}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={
                formik.touched.username && Boolean(formik.errors.username)
              }
            >
              <FormLabel m={0}>2. Username *</FormLabel>
              <FormHelperText m={0}>
                Used to uniquely identify you in the{" "}
                <Text as="span" className="logo-font">
                  youwho
                </Text>{" "}
                ecosystem.
              </FormHelperText>
              <InputGroup mt={1}>
                <InputLeftElement
                  children={<Icon as={BiI.BiUser} mt="8px" w="20px" h="20px" />}
                />
                <Input
                  focusBorderColor="primary.500"
                  variant="flushed"
                  size="lg"
                  id="username"
                  type="text"
                  placeholder="Username"
                  aria-label="Username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  fontWeight="300"
                />
              </InputGroup>
              <FormErrorMessage>
                {formik.touched.username && formik.errors.username}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formik.touched.email && Boolean(formik.errors.email)}
            >
              <FormLabel m={0}>3. Email *</FormLabel>
              <FormHelperText m={0}>
                Used to login to the{" "}
                <Text as="span" className="logo-font">
                  youwho
                </Text>{" "}
                ecosystem. We will never share your email address.
              </FormHelperText>
              <InputGroup mt={1}>
                <InputLeftElement
                  children={
                    <Icon as={BiI.BiEnvelope} mt="8px" w="20px" h="20px" />
                  }
                />
                <Input
                  focusBorderColor="primary.500"
                  variant="flushed"
                  size="lg"
                  id="email"
                  type="email"
                  placeholder="Email"
                  aria-label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  fontWeight="300"
                />
              </InputGroup>
              <FormErrorMessage>
                {formik.touched.email && formik.errors.email}
              </FormErrorMessage>
            </FormControl>

            <Stack spacing={8}>
              <FormControl
                isInvalid={
                  formik.touched.password && Boolean(formik.errors.password)
                }
              >
                <FormLabel m={0}>4a. Password *</FormLabel>
                <FormHelperText m={0}>
                  Password must contain atleast 8 characters, one uppercase, one
                  lowercase, one number and one special case character.
                </FormHelperText>
                <InputGroup mt={1}>
                  <InputLeftElement
                    children={<Icon as={BiI.BiKey} mt="8px" w="20px" h="20px" />}
                  />
                  <Input
                    focusBorderColor="primary.500"
                    variant="flushed"
                    size="lg"
                    id="password"
                    type="password"
                    placeholder="Password"
                    aria-label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    fontWeight="300"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {formik.touched.password && formik.errors.password}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={
                  formik.touched.passwordConfirm &&
                  Boolean(formik.errors.passwordConfirm)
                }
              >
                <FormLabel m={0}>4b. Confirm Password *</FormLabel>
                <FormHelperText m={0}>
                  Repeat the password entered above.
                </FormHelperText>
                <InputGroup mt={1}>
                  <InputLeftElement
                    children={<Icon as={BiI.BiKey} mt="8px" w="20px" h="20px" />}
                  />
                  <Input
                    focusBorderColor="primary.500"
                    variant="flushed"
                    size="lg"
                    id="passwordConfirm"
                    type="password"
                    placeholder="Confirm password"
                    aria-label="Confirm password"
                    value={formik.values.passwordConfirm}
                    onChange={formik.handleChange}
                    fontWeight="300"
                  />
                </InputGroup>
                <FormErrorMessage>
                  {formik.touched.passwordConfirm &&
                    formik.errors.passwordConfirm}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <FormControl
              isInvalid={
                formik.touched.typeboxes && Boolean(formik.errors.typeboxes)
              }
            >
              <FormLabel m={0}>
                5. Tick whether you would like to be a vendor, contributor or
                both *
              </FormLabel>
              <FormHelperText m={0}>
                <Link href="https://why.youwho.io/#faq" isExternal>
                  Click here to learn more about being a vendor and/or
                  contributor
                </Link>
              </FormHelperText>

              <CheckboxGroup colorScheme="red" size="lg">
                <Stack px={4} mt={4} spacing="3">
                  <Checkbox
                    name="typeboxes"
                    onChange={formik.handleChange}
                    value="vendor"
                  >
                    <Text>Vendor</Text>
                  </Checkbox>
                  <Checkbox
                    name="typeboxes"
                    onChange={formik.handleChange}
                    value="contributor"
                  >
                    <Text>Contributor</Text>
                  </Checkbox>
                </Stack>
              </CheckboxGroup>

              <FormErrorMessage>
                {formik.touched.typeboxes && formik.errors.typeboxes}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={
                (formik.touched.friendId && Boolean(formik.errors.friendId)) ||
                Boolean(friendIdCheck)
              }
            >
              <FormLabel m={0}>6. Friend Referral ID (optional)</FormLabel>
              <FormHelperText m={0}>
                If a fellow{" "}
                <Text as="span" className="logo-font">
                  youwho
                </Text>{" "}
                ecosytem User referred you please enter their referral ID here.
                You both will be rewarded with additional{" "}
                <Text as="span" fontWeight="500">
                  YOU
                </Text>{" "}
                tokens.
              </FormHelperText>
              <InputGroup mt={1}>
                <InputLeftElement
                  children={
                    <Icon as={BiI.BiUserPlus} mt="8px" w="20px" h="20px" />
                  }
                />
                <Input
                  focusBorderColor="primary.500"
                  variant="flushed"
                  size="lg"
                  id="friendId"
                  type="text"
                  placeholder="Friends ID"
                  aria-label="Friends ID"
                  value={formik.values.friendId}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  fontWeight="300"
                />
              </InputGroup>
              <FormErrorMessage>
                {(formik.touched.friendId && formik.errors.friendId) ||
                  friendIdCheck}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={
                formik.touched.checkboxes && Boolean(formik.errors.checkboxes)
              }
            >
              <FormLabel m={0}>
                7. Read and tick all of the boxes below to proceed with
                registration *
              </FormLabel>

              <CheckboxGroup colorScheme="red">
                <Stack px={4} mt={4} spacing="3">
                  <Checkbox
                    name="checkboxes"
                    onChange={formik.handleChange}
                    value="1"
                  >
                    <Text fontSize="sm">
                      I have read the 'youwho' lightpaper and understand the
                      direction/timeline of the project. (lightpaper can be
                      viewed at:{" "}
                      <Link
                        isExternal
                        href="https://bit.ly/youwhowp"
                        color="primary.500"
                      >
                        https://bit.ly/youwhowp
                      </Link>
                      )
                    </Text>
                  </Checkbox>
                  <Checkbox
                    name="checkboxes"
                    onChange={formik.handleChange}
                    value="2"
                  >
                    <Text fontSize="sm">
                      I understand and accept that any YOU token rewards I
                      receive will be locked in{" "}
                      <Text as="span" className="logo-font" fontWeight="300">
                        Super
                        <Text as="span" fontWeight="500">
                          Staker
                        </Text>
                      </Text>{" "}
                      until the token officially launches on the Ethereum
                      blockchain.
                    </Text>
                  </Checkbox>
                </Stack>
              </CheckboxGroup>

              <FormErrorMessage>
                {formik.touched.checkboxes && formik.errors.checkboxes}
              </FormErrorMessage>
            </FormControl>

            <Stack spacing={8}>
              <Text color="gray.500">
                Click 'Register' below to continue with registration. An
                authentication email will be sent to your specified email address,
                and you will be taken to a 2nd page to finalise your registration.
              </Text>

              <Button
                type="submit"
                isLoading={formik.isSubmitting}
                variant="outline"
                fontWeight="500"
                size="lg"
                fontSize="xl"
              >
                Register
              </Button>
              {authError && (
                <Flex>
                  <Text color="primary.500" fontWeight="500" align="center">
                    {authError?.message}
                  </Text>
                </Flex>
              )}
            </Stack>
          </Stack>
        </form>
      </Flex>
    </Center>
  );
}

export function FormikStepper({
  children,
  formik2
}) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  console.log(formik2.errors)

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <form onSubmit={async (e) => {
      if (isLastStep()) {
        e.preventDefault()
        await formik2.handleSubmit();
        if (formik2.errors);
      } else {
        e.preventDefault()
        setStep(s => s + 1)
      }

    }}>
      {currentChild}
      {
        step > 0 ?
          <Button onClick={() => { setStep((s) => s - 1); }}>Back</Button>
          : null
      }

      {isLastStep() ? null :
        <Button onClick={() => { setStep((s) => s + 1); }}>Next</Button>
      }

    </form >
  );
}
