import { React, useState, useRef } from 'react'
import { useMoralis } from 'react-moralis'
import { NavLink } from 'react-router-dom'
import { Flex, Stack, Input, InputGroup, Icon, InputLeftElement, InputRightElement, FormControl, Button, FormErrorMessage, Center, Divider, Text } from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import * as BiI from 'react-icons/bi'

import ReCAPTCHA from "react-google-recaptcha";

export default function SignupLoginForm() {

    const { signup, Moralis, authError, login, authenticate, } = useMoralis();
    const [isLoading, setIsLoading] = useState(false);
    const recaptchaRef = useRef();

    const validationSchema = yup.object().shape({
        // username: yup.string().min(3, "Username must be atleast 3 characters long.").max(30, "Username must be 30 characters or less.").required("Username is required."),
        email: yup.string().email("Please enter a valid email.").required("Email is required."),
        password: yup.string().required("Password is required.").matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special case character."
        ),
        // passwordConfirm: yup.string().required("Password confirmation is required.").oneOf([yup.ref('password'), null], 'Passwords must match.')
    })

    const formik = useFormik({
        initialValues: {
            // username: "",
            email: "",
            password: "",
            // passwordConfirm: "",
        },
        onSubmit: (values) => {
            handleSignupLogin(values);
        },
        validationSchema
    })

    const handleSignupLogin = async (values) => {
        setIsLoading(true);
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        if (token) {

            try {
                const { emailExists, usernameExists } = await Moralis.Cloud.run("checkEmailUsername", { email: values.email })
                if (emailExists || usernameExists) {
                    await handleLogin(values);
                } else {
                    await handleSignup(values);
                }
            }
            catch (e) {
                console.error("error at signup/login", e)
            }
        }
        setIsLoading(false);
    }

    const handleSignup = async (values) => {

        try {
            await signup(values.email, values.password, values.email)
            await Moralis.Cloud.run("encryptPwd", { pwd: values.password })
        } catch (e) {
            console.error("error from signup", e);
        }
    }

    const handleLogin = async (values) => {
        await login(values.email, values.password);
        formik.setSubmitting(false);
    }

    const cryptoLogin = async () => {
        setIsLoading(true);
        await authenticate();
        setIsLoading(false);
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={4} >
                    <Button variant="ghost" onClick={cryptoLogin} isLoading={isLoading} fontWeight="500" size="lg" fontSize="xl"><Icon as={BiI.BiWallet} w="24px" h="24px" mr={2} />Login with MetaMask</Button>
                    <Center><Text>or</Text></Center>

                    {/* <FormControl isInvalid={formik.touched.username && formik.errors.username} >
                        <InputGroup>
                            <InputLeftElement children={<Icon as={BiI.BiUser} mt="8px" w="20px" h="20px" />} />
                            <Input
                                focusBorderColor="primary.500"
                                variant="flushed"
                                size="lg"
                                id="username"
                                type="name"
                                placeholder="Username"
                                aria-label="Username"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                fontWeight="300"
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.touched.username && formik.errors.username}</FormErrorMessage>
                    </FormControl> */}

                    <FormControl isInvalid={formik.touched.email && formik.errors.email} >
                        <InputGroup>
                            <InputLeftElement children={<Icon as={BiI.BiEnvelope} mt="8px" w="20px" h="20px" />} />
                            <Input
                                focusBorderColor="primary.500"
                                variant="flushed"
                                size="lg"
                                id="email"
                                type="email"
                                placeholder="Email"
                                aria-label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                fontWeight="300"
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.touched.email && formik.errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={formik.touched.password && formik.errors.password} >
                        <InputGroup>
                            <InputLeftElement children={<Icon as={BiI.BiKey} mt="8px" w="20px" h="20px" />} />
                            <Input
                                focusBorderColor="primary.500"
                                variant="flushed"
                                size="lg"
                                id="password"
                                type="password"
                                placeholder="Password"
                                aria-label="Password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                fontWeight="300"
                            />
                            {authError && <InputRightElement children={<NavLink to="/resetpwd" fontSize="10px" >forgot?</NavLink>} mt="5px" color="gray" _hover={{ fontWeight: "400", color: "gray.700" }} />}
                        </InputGroup>
                        <FormErrorMessage>{formik.touched.password && formik.errors.password}</FormErrorMessage>
                    </FormControl>

                    {/* <FormControl isInvalid={formik.touched.passwordConfirm && formik.errors.passwordConfirm} >
                        <InputGroup>
                            <InputLeftElement children={<Icon as={BiI.BiLock} mt="8px" w="20px" h="20px" />} />
                            <Input
                                focusBorderColor="primary.500"
                                variant="flushed"
                                size="lg"
                                id="passwordConfirm"
                                type="password"
                                placeholder="Confirm password"
                                aria-label="Confirm password"
                                value={formik.values.passwordConfirm}
                                onChange={formik.handleChange}
                                fontWeight="300"
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.touched.passwordConfirm && formik.errors.passwordConfirm}</FormErrorMessage>
                    </FormControl> */}
                    <Stack py="10px" alignItems="center">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.REACT_APP_GOOGLE_RECATPCHA_V2_INVISIBLE_SITE_KEY}
                        />
                        <Button type="submit" isLoading={isLoading} w="100%" variant="ghost" fontWeight="500" size="lg" fontSize="xl" >Signup / Login</Button>
                        {authError && <Flex><Text color="primary.500" fontWeight="500" align="center">{authError.message}</Text></Flex>}
                    </Stack>

                </Stack>
            </form>
            <Center ><Divider w="50%" /></Center>
            <Text as={NavLink} to="/onboard" textAlign="center" _hover={{ color: "inherit" }}>Signup as a <Text as="span" fontWeight="500" >Vendor / Contributor</Text> and receive <Text fontWeight="500" as="span">UHU</Text> token rewards</Text>
            <Center ><Divider w="50%" /></Center>
            <Text as={NavLink} to="/request" textAlign="center" _hover={{ color: "inherit" }}>Purchase <Text as="span" fontWeight="500" >UHU</Text> tokens</Text>
        </>
    )
}
