import { React } from 'react'
import { NavLink } from 'react-router-dom'
import { Text, Center, Icon } from "@chakra-ui/react";

import * as Io5 from 'react-icons/io5'

export default function Withdraw() {

    return (
        <Center as={NavLink} to="/main/stake" maxW="600px" p={8} mt="10vh" mx={[4, "auto", "auto"]} flexDir="column" bg={["", "white", ""]} borderRadius="lg" _hover={{ bg: "#f9f9f9" }} boxShadow={["", "md", ""]} border={["", "1px", ""]} borderColor={["", "gray.100", "gray.100"]}>
            <Icon as={Io5.IoCodeSlash} fontSize="60px" color="primary.500" /><br />
            <Text fontSize="xl" align="center"><Text as="span" fontSize="3xl" fontWeight="500">Check Back Soon</Text><br />Withdrawls from <Text as="span" className="logo-font" fontWeight="300" >Super<Text as="span" fontWeight="500" >Staker</Text></Text> are not yet available.<br />Click here to go back to your staking dashboard.</Text>
        </Center>
    )
}
