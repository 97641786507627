import { React, useEffect, useState } from 'react'
import { useMoralis } from "react-moralis";
import { useHistory, NavLink } from 'react-router-dom';
import { Stack, Spinner, Input, InputGroup, Icon, Link, InputLeftElement, FormControl, Button, FormErrorMessage, FormHelperText, FormLabel, Center, Text, Flex, Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { DateTime } from 'luxon'

import * as BiI from 'react-icons/bi'
import * as AiI from 'react-icons/ai'
import * as HiI from 'react-icons/hi'
import * as MdI from 'react-icons/md'

import ImageUploader from './fileUploader/ImageUploader';

export default function Onboard2({ vendor, contributor }) {

    const { user, Moralis, isAuthenticated } = useMoralis();
    const history = useHistory();
    const [onboardLoading, setOnboardLoading] = useState(false)
    const [emailVerified, setEmailVerified] = useState(user?.attributes?.emailVerified)
    const [products, setProducts] = useState(false);
    const [services, setServices] = useState(false);
    const [productServiceDetails, setProductServiceDetails] = useState("")
    const [contributorDetails2, setContributorDetails2] = useState("")
    const [file1, setFile1] = useState("");
    const [file2, setFile2] = useState("");
    const [file3, setFile3] = useState("");
    const [onboardError, setOnboardError] = useState("");
    const [registerSuccess, setRegisterSuccess] = useState(false);

    const validationSchema = yup.object().shape({
        typeboxes: vendor ? yup.array().min(1, "You must tick at least 1 box.") : yup.array(),
        productService: yup.string().max(1000, "You have entered too many characters, please edit your response to 1000 characters or less.").required("More detail is required."),
        contributorDetails: yup.string().max(1000, "You have entered too many characters, please edit your response to 1000 characters or less.").required("More detail is required."),
        // friendUsername: yup.string().min(3, "Friends Username must be atleast 3 characters long.").max(20, "Friends Username must be 20 characters or less."),
    });

    useEffect(() => {
        if (registerSuccess) {
            setTimeout(() => history.push("/main/stake"), 9000)
        }
        // eslint-disable-next-line
    }, [registerSuccess])

    const formik = useFormik({
        initialValues: {
            typeboxes: [],
            productService: "",
            contributorDetails: "",
            // friendUsername: "",
        },
        onSubmit: (values) => {
            handleRegister(values);

            // console.log("values", values);
            // const { username, password, email, typeboxes, checkboxes } = values;
            // let vendor = "vendor" === typeboxes.find(e => e === "vendor");
            // let contributor = "contributor" === typeboxes.find(e => e === "contributor");
            // console.log("vendor", vendor, "contributor", contributor)
            // formik.setSubmitting(false);
        },
        validationSchema
    })

    const handleRegister = async (values) => {
        const { productService, contributorDetails } = values;
        const username = user.attributes?.username;
        const email = user.attributes?.email;

        try {
            const moralisFile1 = new Moralis.File("user-verification-id", file1);
            user.set("verificationId", moralisFile1);
            const moralisFile2 = new Moralis.File("user-verification-selfie", file2);
            user.set("verificationSelfie", moralisFile2);
            if (file3) {
                const moralisFile3 = new Moralis.File("user-certification", file3);
                user.set("serviceCertification", moralisFile3);
            }

            await user.save();

            console.log("file save to user done")
        } catch (err) {
            console.error("error saving files to user", err)
        }

        try {
            const outcome = await Moralis.Cloud.run("setOnboardFinalise", { products, services, productService, contributorDetails, vendor, contributor, username, email });

            // console.log("result from register", outcome)

            if (outcome.result) {
                setRegisterSuccess(true);
            }
            if (outcome.error) setOnboardError(outcome.error);

        } catch (error) {
            console.error("error from setonboardfinalise", error)
        }

        formik.setSubmitting(false);
    }

    if (onboardLoading) {
        return (
            <Center w="100%" h="50vh" flexDir="column">
                <Spinner size="xl" />
                <br />
                <Text fontSize="xl">Loading ...</Text>
            </Center>
        )
    }

    if (registerSuccess) {
        return (
            <Center as={NavLink} to="/main/stake" maxW="600px" p={8} mt="10vh" mx={[4, "auto", "auto"]} flexDir="column" bg={["", "white", ""]} borderRadius="lg" _hover={{ bg: "#f9f9f9" }} boxShadow={["", "md", ""]} border={["", "1px", ""]} borderColor={["", "gray.100", "gray.100"]}>
                <Icon as={MdI.MdOutlineCelebration} fontSize="60px" color="primary.500" /><br />
                <Text fontSize="xl" align="center"><Text as="span" fontSize="3xl" fontWeight="500">Congratulations!</Text><br />Your registration to become a {vendor && contributor
                    ? "Vendor and Contributor"
                    : vendor && !contributor
                        ? "Vendor"
                        : !vendor && contributor
                            ? "Contributor"
                            : "User"} in the <Text as="span" className="logo-font">youwho</Text> ecosystem is complete.<br />You will be redirected to the Staking page in 9 seconds.</Text>
            </Center>
        )
    }

    if (!emailVerified) {
        return (
            <Center maxW="600px" p={8} mt="10vh" mx={[4, "auto", "auto"]} flexDir="column" bg={["", "white", ""]} borderRadius="lg" _hover={{ bg: "#f9f9f9" }} boxShadow={["", "md", ""]} border={["", "1px", ""]} borderColor={["", "gray.100", "gray.100"]}>
                <Icon as={MdI.MdOutlineMarkEmailRead} fontSize="60px" color="primary.500" /><br />
                <Text fontSize="xl" align="center"><Text as="span" fontSize="3xl" fontWeight="500">Verify your email</Text><br />Please check your email <Text as="span" fontWeight="500">{user.get("email")}</Text> and follow the verification link in the email sent to you before proceeding with Vendor / Contributor registration.</Text>
            </Center>
        )
    }

    return (
        <Flex minH="50vh" w="100%" p={[0, 4, 4]} alignItems="flex-start" flexWrap="wrap">

            <Flex
                minH="400px"
                bg="white"
                maxW="600px"
                boxShadow={[0, "md", "md"]}
                flexDir="column"
                borderRadius={[0, "lg", "lg"]}
                mx="auto"
                flexGrow="1"
                border="1px" borderColor="gray.100"
            >

                <Flex
                    pt="24px"
                    px={6}
                    pb={5}
                    display="inline-flex"
                    alignItems="center"
                    bg="#f5f5f5"
                    borderTopRadius={[0, "lg", "lg"]}
                    flexDir="column"
                >
                    <Flex alignItems="center">
                        <Icon as={AiI.AiOutlineForm} fontSize={["3xl", "4xl", "4xl"]} mr={4} color="primary.500" /><Text color="gray" fontSize={["2xl", "2xl", "2xl"]} fontWeight="400">Finalise Vendor & Contributor Registration</Text>
                    </Flex>
                    <Flex px={2} pt={2}>
                        <Text color="gray" fontSize="sm">Only a few more steps to go before you can claim those sweet <Text as="span" fontWeight="500">UHU</Text> token rewards and start automagically staking!</Text>
                    </Flex>
                </Flex>

                <form onSubmit={formik.handleSubmit}>

                    <Stack spacing={8} p={8} >

                        {vendor &&
                            <>
                                <FormControl isInvalid={formik.touched.typeboxes && formik.errors.typeboxes}>
                                    <FormLabel m={0}>1a. Tick whether you plan to offer Products and/or Services *</FormLabel>
                                    <FormHelperText m={0} ><Link href="https://why.youwho.io/#faq" isExternal>Click here to learn more about being a Vendor</Link></FormHelperText>

                                    <CheckboxGroup
                                        colorScheme="red"
                                        id="typeboxes"
                                    >
                                        <Stack px={4} mt={4} spacing="3">
                                            <Checkbox name="typeboxes" onChange={(e) => { formik.handleChange(e); setProducts(e.target.checked); }} value="products"><Text >Products</Text></Checkbox>
                                            <Checkbox name="typeboxes" onChange={(e) => { formik.handleChange(e); setServices(e.target.checked); }} value="services"><Text >Services</Text></Checkbox>
                                        </Stack>
                                    </CheckboxGroup>

                                    <FormErrorMessage>{formik.touched.typeboxes && formik.errors.typeboxes}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={formik.touched.productService && formik.errors.productService}>
                                    <FormLabel m={0}>1b. Describe Products and/or Services *</FormLabel>
                                    <FormHelperText m={0}>Please describe the types of Products and/or Services you plan to offer. Provide as much detail as possible to give us a good understanding of the various products and services Vendors plan to offer once the ecosystem goes live.</FormHelperText>
                                    <InputGroup mt={2}>
                                        <InputLeftElement children={<Icon as={HiI.HiOutlinePencil} mb="16px" w="22px" h="22px" color="gray" />} />
                                        <Input
                                            focusBorderColor="primary.500"
                                            variant="flushed"
                                            id="productService"
                                            name="productService"
                                            type="text"
                                            as="textarea"
                                            placeholder="Provide details of your products and/or services here..."
                                            aria-label="Products and Services"
                                            value={formik.values.productService}
                                            onChange={e => { formik.handleChange(e); setProductServiceDetails(e.target.value) }}
                                            fontWeight="300"
                                            minHeight="150px"
                                            isInvalid={productServiceDetails.length > 1000 ? true : false}
                                        />
                                    </InputGroup>
                                    <FormHelperText as="i" color={productServiceDetails.length > 1000 ? "crimson" : "gray.500"} >{1000 - productServiceDetails.length} char. remaining.</FormHelperText>

                                    <FormErrorMessage>{formik.touched.productService && formik.errors.productService}</FormErrorMessage>
                                </FormControl>


                                {services &&
                                    <ImageUploader
                                        setFile={setFile3}
                                        imageSize={1024000}
                                        label={<>1c. Upload Professional Certificate/License (optional)</>}
                                        helper={<>If you have any Professional Certificates or Licenses you would like to upload to bring even more trustworthiness to the services you plan to offer, please upload them here. If you have multiple certificates/licenses combine them all into one image.<br />Image must be less than 1MB in size.<br /><Link href="https://why.youwho.io/#faq" isExternal>Click here to learn more about Verified Users</Link></>}
                                        isRequired={false}
                                    />
                                }

                            </>
                        }

                        {contributor &&
                            <>
                                <FormControl isInvalid={formik.touched.contributorDetails && formik.errors.contributorDetails}>
                                    <FormLabel m={0}>{vendor ? contributor ? "2" : "1" : "1"}. Describe how you may be able to contribute *</FormLabel>
                                    <FormHelperText m={0}>Please describe any previous work and/or life experiences you have that you could use to help contribute to the ecosystem.</FormHelperText>
                                    <InputGroup mt={2}>
                                        <InputLeftElement children={<Icon as={HiI.HiOutlinePencil} mb="16px" w="22px" h="22px" color="gray" />} />
                                        <Input
                                            focusBorderColor="primary.500"
                                            variant="flushed"
                                            id="contributorDetails"
                                            name="contributorDetails"
                                            type="text"
                                            as="textarea"
                                            placeholder="I have experience as a community manager, software developer, arbitrator/lawyer, online marketer/influencer ..."
                                            aria-label="Contributor details"
                                            value={formik.values.contributorDetails}
                                            onChange={e => { formik.handleChange(e); setContributorDetails2(e.target.value) }}
                                            fontWeight="300"
                                            minHeight="150px"
                                            isInvalid={contributorDetails2.length > 1000 ? true : false}
                                        />
                                    </InputGroup>
                                    <FormHelperText as="i" color={contributorDetails2.length > 1000 ? "crimson" : "gray.500"} >{1000 - contributorDetails2.length} char. remaining.</FormHelperText>

                                    <FormErrorMessage>{formik.touched.contributorDetails && formik.errors.contributorDetails}</FormErrorMessage>
                                </FormControl>
                            </>
                        }

                        {(vendor || contributor) &&
                            <>
                                <ImageUploader
                                    setFile={setFile1}
                                    imageSize={512000}
                                    label={<>{vendor ? contributor ? "3a" : "2a" : "2a"}. Upload ID / Passport</>}
                                    helper={<>ID verification is required to become a Verified Vendor or Contributor in the <Text as="span" className="logo-font">youwho</Text> ecosystem as you will have priviledged access and authorization.<br />Please upload either an image of the front page of your passport or the front of your national ID card.<br />Image must be less than 500KB in size.<br /><Link href="https://why.youwho.io/#faq" isExternal>Click here to learn more about Verified Users</Link></>}
                                    isRequired={true}
                                />

                                <ImageUploader
                                    setFile={setFile2}
                                    imageSize={512000}
                                    label={<>{vendor ? contributor ? "3b" : "2b" : "2b"}. Upload Selfie</>}
                                    helper={<>Please upload an image of you holding the ID uploaded above, along with a piece of paper with <Text as="span" fontWeight="400">"youwho ecosystem {DateTime.now().toISODate()}"</Text> written on it.<br />Image must be less than 500KB in size.<br /><Link href="https://why.youwho.io/#faq" isExternal>Click here to learn more about Verified Users</Link></>}
                                    isRequired={true}
                                />
                            </>
                        }

                        <Text color="gray.500">Click 'Register' below to complete your registration and claim your <Text as="span" fontWeight="500">UHU</Text> tokens. Your tokens will appear in the <Text as="span" fontWeight="500">stake</Text> page and will automagically be staked for you.</Text>

                        <Button type="submit" isLoading={formik.isSubmitting} variant="outline" fontWeight="500" size="lg" fontSize="xl">Register</Button>
                        {onboardError && <Flex><Text color="primary.500" fontWeight="500" align="center">{onboardError}</Text></Flex>}

                    </Stack>

                </form>

            </Flex>

        </Flex>
    )
}
