import React from 'react'
import { Center } from "@chakra-ui/react";


export default function YouwhoLogo({ ...props }) {
    return (
        <Center w={props.wdth} >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184.78 184.78" {...props} >
                <title>youwho | Decentralized Ecosystem to Live Self Employed</title>
                <g className="cls-1" style={{ isolation: "isolate" }}>
                    <g id="Layer_1">
                        <image className="cls-2" style={{ opacity: "0.2", mixBlendMode: "multiply" }} width="147" height="168" transform="translate(26.34 10.89)" />
                        <path className="cls-3" style={{ fill: "#ffd91d" }} d="M92.41,41.39a7.22,7.22,0,0,0-7-7.43,7.23,7.23,0,1,0-.12,14.46A7.36,7.36,0,0,0,92.41,41.39Z" />
                        <path className="cls-3" style={{ fill: "#ffd91d" }} d="M117.91,78a28.27,28.27,0,0,0,16.2-7.88l.18-.18a7.24,7.24,0,0,1,5.24-2.4c1.83,0,21.11,0,21.11,0l-6.88,9.59C145,89.47,132.89,96.41,117.93,97.82Z" />
                        <path className="cls-4" style={{ fill: "#ff3000" }} d="M118,97.81l0-19.77c0-3.89,0-34.35,0-48.19a6.56,6.56,0,0,0-6.28-6.8h-7a6.56,6.56,0,0,0-6.28,6.81V78.28l-12.13,0a28.39,28.39,0,0,1-27.92-28c-.08-5.62-.07-11.2-.06-16.6a6.32,6.32,0,0,0-1.4-4.1,6,6,0,0,0-4.66-2.19H44.9a6.39,6.39,0,0,0-4.1,1.4,6.05,6.05,0,0,0-2.18,4.66c0,7.64.08,13.53.26,19.09C39.26,64.09,43.74,74.37,52.2,83,61.82,92.9,73.89,97.9,88.11,97.91l10.3,0,0,37.19a11.61,11.61,0,0,1-9.78,11.73,11,11,0,0,1-1.9.17,11.43,11.43,0,0,1-11.3-10.77c-.11-3.74-.11-7.46-.1-10.92a6.32,6.32,0,0,0-1.39-4.12A6,6,0,0,0,69.23,119h-7.7a6.1,6.1,0,0,0-6.06,5.92A104.72,104.72,0,0,0,56,139c1.37,12,8,20.77,19.25,25.33a32,32,0,0,0,12.05,2.47h0c6.94,0,13.48-2.49,19.42-7.4,7.58-6.26,11.38-14.52,11.3-24.56C117.91,126.17,118,101.48,118,97.81Z" />
                        <polygon className="cls-5" style={{ fill: "tomato" }} points="98.38 78.28 117.92 78.04 117.95 97.81 98.41 97.93 98.38 78.28" />
                    </g>
                </g>
            </svg>
        </Center>
    )
}
