import { React, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Stack, Input, Text, InputGroup, Icon, IconButton, InputLeftElement, FormControl, Button, FormErrorMessage } from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import moralis from "moralis"

import * as BiI from 'react-icons/bi'

const resetPasswordFn2 = async (email) => {

    await moralis.User.requestPasswordReset(email)
        .then(() => {
            return;
        }).catch((error) => {
            throw error
        });
}

export default function PasswordReset({ toast }) {

    const [resetEmail, setResetEmail] = useState("")
    const history = useHistory();

    const validationSchema = yup.object().shape({
        email: yup.string().email("Please enter a valid email.").required("Email is required."),
    })

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        onSubmit: (values) => {
            resetPasswordFn1(values);
        },
        validationSchema
    })

    const resetPasswordFn1 = async ({ email }) => {

        await resetPasswordFn2(email)
            .then(() => {
                toast({
                    title: "Password reset request successful.",
                    description: <Text>We have sent an email to <b>{email}</b><br />Please follow the link to reset your password.</Text>,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                });
                history.push("/login");
            }).catch((error) => {
                toast({
                    title: "Oops we encountered an error.",
                    description: error.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            });
    }

    return (
        <>
            <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e) }}>
                <Stack spacing={4} >

                    <FormControl isInvalid={formik.touched.email && formik.errors.email} >
                        <InputGroup>
                            <InputLeftElement children={<Icon as={BiI.BiEnvelope} mt="8px" w="20px" h="20px" />} />
                            <Input
                                focusBorderColor="primary.500"
                                variant="flushed"
                                size="lg"
                                id="email"
                                name="email"
                                placeholder="Email"
                                aria-label="Email"
                                value={resetEmail}
                                onChange={(e) => { formik.handleChange(e); setResetEmail(e.target.value) }}
                                fontWeight="300"
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.touched.email && formik.errors.email}</FormErrorMessage>
                    </FormControl>

                    <Button type="submit" variant="ghost" fontWeight="500" size="lg" fontSize="xl">Reset Password</Button>

                </Stack>
            </form>
            <IconButton icon={<BiI.BiLeftArrowCircle />} variant="unstyled" pl={2} fontSize={["3xl"]} _hover={{ color: "primary.500" }} color="gray" onClick={() => { history.push("/"); window.location.reload(false); }} />

        </>
    )
}
